import React, { Component } from "react";
import { EmptyInfo } from "../../../../common";
import { connect } from "react-redux";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import "./messagedetail.scss";
import { Messages } from "../../../../utilities";
import { Col, Row, Space } from "antd";

class MessageDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("M/D/YY");
  };
  renderTime = (date) => {
    return moment
      .utc(date)
      .local()
      .format("hh:mm a");
  };
  render() {
    const {
      labels,
      selectedMessage,
      IsFreelancer,
      handleVisitRequest,
      isReply,
      handleOpen,
    } = this.props;
    return (
      <div className="new-user-detail-component">
        <div className="inner-user">
          {!selectedMessage.MessageId ? (
            <EmptyInfo>{labels.Message_Inbox}</EmptyInfo>
          ) : (
            <>
              <p className="user-title">{selectedMessage.Subject}</p>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">{Messages.message.labels.from}:</p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {ReactHtmlParser(selectedMessage.SenderName)}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">{Messages.message.labels.date}:</p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {this.renderDate(selectedMessage.DateCreated)}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">{Messages.message.labels.time}:</p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {this.renderTime(selectedMessage.DateCreated)}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="auto">
                  <p className="user-detail" style={{ whiteSpace: "pre" }}>
                    {ReactHtmlParser(selectedMessage.MessageBody)}
                  </p>
                </Col>
              </Row>
              <Row className="">
                <Space
                  size={[8, 8]}
                  style={{
                    margin: "auto",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {selectedMessage &&
                    selectedMessage.RequestId &&
                    IsFreelancer && (
                      <button
                        className="button-footer-opportunity pl-4 pr-4"
                        onClick={handleVisitRequest}
                      >
                        Visit
                      </button>
                    )}
                  {isReply && (
                    <button
                      className="button-footer-opportunity pl-4 pr-4"
                      onClick={handleOpen}
                    >
                      Reply
                    </button>
                  )}
                </Space>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(MessageDetail);
