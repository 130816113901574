import React, { Component } from "react";
import "./createMessage.scss";
import { connect } from "react-redux";
import { Messages } from "../../../../utilities";
import { LoadingMask, Button } from "../../../../common";
import { RichTextEditor, DragImage } from "../../../../components";
import { Col, Input, Row } from "antd";
class CreateMessage extends Component {
  render() {
    const {
      messageForm,
      handleContentChange,
      handleContentEmailChange,
      isFetching,
      onMessageSend,
      labels,
      onAssortSendType,
    } = this.props;
    const { title, content, isInvalidTitle, isInvalidContent } = messageForm;
    return (
      <div className="new-user-detail-component">
        <div className="inner-user">
          {isFetching && <LoadingMask />}
          <div className="container-collapse">
            <div>
              <Input
                size="large"
                className={`bg-color-for-opportunity  ${
                  isInvalidTitle ? "inValid" : ""
                }`}
                bordered={false}
                type="text"
                autoComplete="off"
                name="title"
                value={title ? title : ""}
                placeholder={
                  onAssortSendType == 1
                    ? labels.EMAIL_SUBJECT_LABEL || "Email Subject"
                    : labels?.searcher_create_message_subject_placeholder
                }
                onChange={handleContentChange}
                testId="create-message-title"
              />
            </div>
          </div>

          {onAssortSendType == 1 ? (
            <RichTextEditor
              id="richtxtFeedbackContent"
              value={content}
              isInvalidValue={isInvalidContent}
              handleChange={handleContentEmailChange}
            />
          ) : (
            <textarea
              name="content"
              value={content ? content : ""}
              className={`textarea-field ${isInvalidTitle ? "inValid" : ""}`}
              onChange={handleContentChange}
              style={{resize:'none'}}
              placeholder={
                onAssortSendType == 1
                  ? labels.EMAIL_CONTENT_LABEL || "Email Content"
                  : labels?.searcher_create_message_content_placeholder
              }
              rows="13"
              test-data-id="create-message-body"
            />
          )}
          <div className="align-center">
            <button
              className="button-footer-opportunity pl-4 pr-4"
              onClick={onMessageSend}
            >
              {Messages.message.button.send}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(CreateMessage);
