import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../../components";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import map from "lodash/map";
import includes from "lodash/includes";
import { ConfirmDialog, LoadingMaskRow, EmptyInfo } from "../../../common";
import { notificationAction, unreadMessagesAction } from "../../../actions";
import "./messageinbox.scss";
import {
  getInboxMessagesApi,
  updateMessageApi,
  sendMessage,
  getRequestStatus,
  getCollabStatus,
} from "../messageApi";
import { privateRoutes } from "../../../Routes/routing";
import { Messages } from "../../../utilities";
import MessageDetail from "./components/MessageDetail";
import { onStateChangeAction } from "./messageInboxAction";
import { Col, Input, Row, Space } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import MobileInboxDetailModal from "./components/mobileModal";
import CreateMessageModal from "./components/createMessageModal";

class MessageInbox extends Component {
  state = {
    selectedmessage: [],
    CollabStatus: "",
    RequestStatus: "",
    open: false,
  };

  componentDidMount() {
    this.getAllMessages();
  }

  getAllMessages = () => {
    this.props.onStateChangeAction({ isFetching: true });

    getInboxMessagesApi().then((data) => {
      if (data.success) {
        const messageList = map(data.items, (item) => ({
          ...item,
          userRequest: first(item.UserRequestMessage),
        }));

        this.props.onStateChangeAction({
          messageList,
          filterMessages: messageList,
          isFetching: false,
        });
        const spliturl = this.props.location.pathname.match(/\d+/g);
        if (spliturl != null) {
          let filterrequestid = data.items.filter(
            (a) => a.MessageId == spliturl[0]
          );
          this.handleMessageSelect(filterrequestid[0]);
        }
      }
    });
  };

  handleContentChange = (e) => {
    const { value, name } = e.target;
    const { messageForm } = this.props;
    const data = {
      ...messageForm,
      [name]: value,
    };
    this.props.onStateChangeAction({ messageForm: data });
  };

  handleReply = () => {
    this.props.onStateChangeAction({ isReply: true });
  };

  handleVisitRequest = () => {
    const { selectedMessage } = this.props;
    var reqType = 1;
    var reqId = selectedMessage.RequestId;
    var IsCollaboration = selectedMessage.IsCollaboration;
    if (selectedMessage.IsCollaboration) {
      const {
        CollaborationIdm,
        IsAccepted,
        IsDeclined,
        IsIProClosed,
        IsIProDeleted,
        IsIProRead,
        IsSearcherClosed,
        IsSearcherDeleted,
        IsSearcherRead,
      } = this.state.CollabStatus;

      if (!IsAccepted && !IsDeclined) {
        this.props.history.push({
          pathname: privateRoutes.iproNewCollaborations.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      } else if (
        IsAccepted &&
        !IsSearcherClosed &&
        !IsIProClosed &&
        !IsSearcherDeleted &&
        !IsIProDeleted
      ) {
        this.props.history.push({
          pathname: privateRoutes.iproActiveCollaborations.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      } else if (IsSearcherClosed || IsIProClosed) {
        this.props.history.push({
          pathname: privateRoutes.iproNewCollaborations.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      } else if (IsDeclined) {
        this.props.history.push({
          pathname: privateRoutes.IproCollaborationDeclined.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      }
    } else {
      const { IsConfirmed, IsDeclined, IsDeleted } = this.state.RequestStatus;
      if (!IsConfirmed && !IsDeclined && !IsDeleted) {
        this.props.history.push({
          pathname: privateRoutes.iproNewOpportunity.path,
          search: "",
          hash: "",
          state: { visitRequestId: reqId },
        });
      } else if (IsConfirmed) {
        this.props.history.push({
          pathname: privateRoutes.iproAccpetedOpportunity.path,
          search: "",
          hash: "",
          state: { visitRequestId: reqId },
        });
      } else if (IsDeclined) {
        this.props.history.push({
          pathname: privateRoutes.iproDeclinedOpportunity.path,
          search: "",
          hash: "",
          state: { visitRequestId: reqId },
        });
      }
    }
  };

  handleSubmitMessage = () => {
    const {
      messageForm: { content, title },
    } = this.props;
    const { notificationAction } = this.props;
    if (isEmpty(content)) {
      notificationAction({
        status: "error",
        message: "Please add content to proceed",
      });
      return;
    }

    const message = {
      IsRead: false,
      MessageBody: content,
      MessageId: 0,
      Subject: this.props.selectedMessage.Subject,
      UserIds: [this.props.selectedMessage.SenderId],
      UserRequestMessageId: 0,
    };
    this.props.onStateChangeAction({ isFetching: true });
    sendMessage({ message })
      .then((response) => {
        this.props.onStateChangeAction({ messageForm: {} });
        this.getAllMessages();
        this.setState((st) => ({ ...st, open: false }));
        notificationAction({
          status: "success",
          message: this.props.labels.InfoIProCreateMsgAddSuccessfully,
        });
      })
      .catch((error) => {
        this.props.onStateChangeAction({ isFetching: false });
      });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { messageList } = this.props;
    const filterMessages = filter(messageList, (message) =>
      includes(toLower(message.Subject), toLower(value))
    );
    this.props.onStateChangeAction({ filterMessages, searchKey: value });
  };

  handleDeleteMessage = (message) => {
    const {
      UserRequestMessageId: { UserRequestMessageId },
    } = message;
    this.props.onStateChangeAction({
      deletedId: message.UserRequestMessageId
        ? message.UserRequestMessageId
        : null,
      dialogMessage: this.props.labels.AlrtDelMsg,
    });
  };

  handleYesClick = () => {
    const { deletedId } = this.props;
    const message = {
      MessageId: deletedId,
      isDeleteInboxMessage: true,
    };
    this.props.onStateChangeAction({ dialogMessage: "" });
    updateMessageApi({ message }).then((data) => {
      if (data.success) {
        const info = {
          message: this.props.labels.InfoIProInboxMsgDeleteSuccessfully,
          status: "success",
        };
        this.props.notificationAction(info);
        this.getAllMessages();
        this.props.onStateChangeAction({
          selectedMessage: {},
          deletedId: null,
        });
      }
    });
  };
  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };

  handleMessageSelect = async (selectedMessage) => {
    const {
      messageList,
      unreadMessagesAction,
      onStateChangeAction,
    } = this.props;

    if (selectedMessage.IsCollaboration) {
      const response = await getCollabStatus({
        colId: selectedMessage.CollaborationId,
      });

      if (response?.success) {
        this.setState({ CollabStatus: response.items });
      }
    } else {
      if (selectedMessage.RequestId) {
        const response = await getRequestStatus({
          reqId: selectedMessage.RequestId,
        });

        if (response?.success) {
          this.setState({ RequestStatus: response.items });
        }
      }
    }

    if (selectedMessage?.IsRead) {
      onStateChangeAction({ selectedMessage, isReply: false });
      return;
    }

    const message = {
      IsRead: true,
      MessageId: selectedMessage.UserRequestMessageId,
    };

    const updateMessageResult = await updateMessageApi({ message });

    if (updateMessageResult?.success) {
      const unreadMessages = filter(
        messageList,
        (message) =>
          message.MessageId !== selectedMessage.MessageId && !message.IsRead
      );

      unreadMessagesAction({ unreadMessages: unreadMessages.length });

      const filterMessages = map(messageList, (message) => {
        if (message.MessageId === selectedMessage.MessageId) {
          message.IsRead = true;
        }
        return message;
      });

      onStateChangeAction({ filterMessages });
    }

    onStateChangeAction({ selectedMessage, isReply: false });
  };

  handleClose = () => {
    this.setState((st) => ({ ...st, mobileModal: false }));
  };

  handleReplyModal = () => {
    this.setState((st) => ({ ...st, open: true }));
  };

  handleReplyModalClose = () => {
    this.setState((st) => ({ ...st, open: false }));
  };

  render() {
    const { labels, isHelpActive, IsFreelancer } = this.props;
    const {
      messageForm,
      dialogMessage,
      selectedMessage,
      filterMessages,
      isReply,
      isFetching,
      searchKey,
    } = this.props;
    return (
      <PageWrapper className="message-inbox">
        {dialogMessage && (
          <ConfirmDialog testId="message-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="messageinbox-delete-yes"
                onClick={this.handleYesClick}
              >
                {Messages.feedback.confirm.Yes}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="messageinbox-delete-no"
                onClick={this.handleNoClick}
              >
                {Messages.feedback.confirm.No}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <MobileInboxDetailModal
          mobileModal={this?.state?.mobileModal}
          handleClose={this.handleClose}
          selectedMessage={selectedMessage}
          labels={labels}
          handleVisitRequest={this.handleVisitRequest}
          IsFreelancer={IsFreelancer}
          handleOpen={this.handleReplyModal}
        />
        <CreateMessageModal
          open={this?.state?.open}
          handleContentChange={this?.handleContentChange}
          messageForm={messageForm}
          onSubmitMessage={this?.handleSubmitMessage}
          handleReplyModalClose={this?.handleReplyModalClose}
        />
        <Row className="new-search-resume-main">
          <Col xs={24} md={10} className="shortlist-lsit-section">
            <div className="h-100">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Input
                    size="large"
                    placeholder={labels?.searcher_inbox_search_placeholder}
                    onChange={this.handleSearchChange}
                    value={searchKey}
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    paddingRight: "6px",
                    overflow: "auto",
                  }}
                >
                  {isFetching ? (
                    <LoadingMaskRow />
                  ) : (
                    <>
                      {filterMessages?.length > 0 ? (
                        <Space
                          size={[6, 6]}
                          wrap
                          className="short-list-new company-list"
                        >
                          {filterMessages?.map((item) => (
                            <div
                              onClick={() => {
                                const mediaQuery = window.matchMedia(
                                  "(max-width: 768px)"
                                );
                                if (mediaQuery?.matches == true) {
                                  this.setState((st) => ({
                                    ...st,
                                    mobileModal: true,
                                  }));
                                }
                                this.handleMessageSelect(item);
                              }}
                              key={item.MessageId}
                              className={`${
                                item.MessageId === selectedMessage.MessageId
                                  ? "background-shortlist short-list-item"
                                  : "short-list-item"
                              }`}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <p
                                className="text-left"
                                style={
                                  item.IsRead == false
                                    ? {
                                        color: "#f6aa3a",
                                        margin: 0,
                                        fontWeight: 600,
                                      }
                                    : { margin: 0, fontWeight: 600 }
                                }
                              >
                                {item?.Subject}
                              </p>
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "18px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDeleteMessage(item);
                                }}
                              />
                            </div>
                          ))}
                        </Space>
                      ) : (
                        <EmptyInfo>
                          {labels.EMBARK_NO_ITEM_TO_DISPLAY}
                        </EmptyInfo>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>

          <Col md={14} xs={0} offset={0.5} className="h-100">
            <div className="column-secend">
              {isEmpty(selectedMessage) ? (
                <EmptyInfo>{labels.Message_Sent}</EmptyInfo>
              ) : (
                <MessageDetail
                  selectedMessage={selectedMessage}
                  labels={labels}
                  handleVisitRequest={this.handleVisitRequest}
                  IsFreelancer={IsFreelancer}
                  isReply={isReply}
                  handleOpen={this.handleReplyModal}
                />
              )}
            </div>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  messageInbox,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));

  return { labels, user, isHelpActive, IsFreelancer, ...messageInbox };
};

export default connect(mapStateToProps, {
  notificationAction,
  unreadMessagesAction,
  onStateChangeAction,
})(MessageInbox);
