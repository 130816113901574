import React from "react";
import "./resumeList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row } from "antd";
import { EmptyInfo, LoadingMaskRow } from "../../../../../../common";
import { EnvironmentOutlined } from "@ant-design/icons";
import { join } from "lodash";

const ShortListResume = ({
  resumeList,
  state,
  fetchMoreResumes,
  handleModalClick,
  label,
}) => {
  const renderValue = (resume) => {
    const ProfileValue = resume.map((item) => item.ProfileValue);
    return join(ProfileValue, ", ");
  };

  return (
    <div className="new-create-opportunity">
      {resumeList?.length > 0 && (
        <InfiniteScroll
          dataLength={resumeList?.length}
          hasMore={state?.hasMore}
          next={fetchMoreResumes}
          loadMore={fetchMoreResumes}
          loader={<LoadingMaskRow />}
          scrollThreshold="100px"
          style={{
            width: "100%",
          }}
          height={"100%"}
        >
          {resumeList?.map((single, index) => (
            <Col
              xs={24}
              key={index}
              onClick={() => {
                handleModalClick(single);
              }}
            >
              <Row
                wrap={false}
                style={{
                  width: "100%",
                  margin: "10px 0px",
                  alignItems: "center",
                }}
              >
                <Col flex="auto">
                  <div className="column-resume-list">
                    <Row
                      gutter={[8, 8]}
                      className="align-items-center justify-content-between"
                    >
                      <Col
                        xs={{ order: 1, span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="list-item-title">
                            {renderValue(single?.Profiles)}
                          </p>
                        </div>
                      </Col>
                      <Col xs={{ order: 2 }} md={{ order: 3, span: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <EnvironmentOutlined
                            size={"large"}
                            style={{
                              fontSize: "large",
                            }}
                          />
                          <p
                            className="location-"
                            style={{
                              marginLeft: "12px",
                            }}
                          >
                            {single?.Region}
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3" gutter={[8, 8]}>
                      <Col xs={24} md={22} className="row-role">
                        {single?.Skills?.slice(0, 3)?.map((row, index) => (
                          <div key={index} className="role-btn">
                            <button className="btn-action w-100">
                              {row?.SkillValue}
                            </button>
                          </div>
                        ))}
                        {single?.Skills?.length > 3 && (
                          <button className="btn-more">
                            {`+ ${single?.Skills?.length - 3}`}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </InfiniteScroll>
      )}
      {resumeList?.length == 0 && (
        <EmptyInfo>{label || "No Resume found"} </EmptyInfo>
      )}
    </div>
  );
};

export default ShortListResume;
