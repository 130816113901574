import React, { Component } from "react";
import { connect } from "react-redux";
import Collaboration from "../common/Collaboration";
import { notificationAction } from "../../../actions";
import NewCollaboration from "../common/newCollaboration";

class NewSearcherCollaborationSent extends Component {
  render() {
    const {
      userId,
      labels,
      IsSearcher,
      notificationAction,
      isHelpActive,
      location,
    } = this.props;
    const {
      SearcherSentCollaborationSearchPlaceholder,
      SearcherSentCollaborationDetailHeadingText,
      TooltipSearcherSentCollaborationListExpand,
      TooltipSearcherSentCollaborationListCollapse,
      HlpTooltipSearcherSentCollaborationListExpand,
      HlpTooltipSearcherSentCollaborationListCollapse,
      SearcherSentCollaborationListColumnText,

      TooltipSearcherSentCollaborationDetailExpand,
      TooltipSearcherSentCollaborationDetailCollapse,
      HlpTooltipSearcherSentCollaborationDetailExpand,
      HlpTooltipSearcherSentCollaborationDetailCollapse,
      SearcherSentCollaborationDetailColumnText,
      SearcherSentCollaborationUserDetailHeaderText,
      TooltipSearcherSentCollaborationUserDetailExpand,
      TooltipSearcherSentCollaborationUserDetailCollapse,
      HlpTooltipSearcherSentCollaborationUserDetailExpand,
      HlpTooltipSearcherSentCollaborationUserDetailCollapse,
      SearcherSentCollaborationUserDetailColumnText,

      SearcherSentCollaborationUserNameLabel,
      SearcherSentCollaborationPositionLabel,
      SearcherSentCollaborationEmailLabel,
      SearcherSentCollaborationPhoneLabel,
      SearcherSentCollaborationWebUrlLabel,
      SearcherSentCollaborationStartDateLabel,
      SearcherSentCollaborationDurationLabel,
      SearcherSentCollaborationCompensationLabel,
      SearcherSentCollaborationCompanyLabel,
      SearcherSentCollaborationDescriptionLabel,
      SearcherSentCollaborationAcceptedLabel,
      SearcherSentCollaborationInterestOrNotLabel,
      SearcherSentCollaborationEmptyInfo,
      InfoSearcherCollaborationDeleted,
      InfoSearcherSentCollaborationDeleteConfirmationMsg,
      collaborationDeleteCurtainNOBtnText,
      collaborationDeleteCurtainYESBtnText,
      TooltipSearcherCollaborationAddNewCollaboration,
      helpTooltipSearcherCollaborationAddNewCollaboration,
      SearcherCollaborationCreateNewTitle,
    } = labels;
    return (
      <NewCollaboration
        isAddButton={true}
        IsSearcher={IsSearcher}
        isHelpActive={isHelpActive}
        isSentView
        notificationAction={notificationAction}
        url={`/GetAllCollaborationByUserId?IsAccepted=false`}
        // list section system label
        searchInputPlaceholder={SearcherSentCollaborationSearchPlaceholder}
        toolTipExpandList={TooltipSearcherSentCollaborationListExpand}
        helpTooltipExpandList={HlpTooltipSearcherSentCollaborationListExpand}
        toolTipCollapseList={TooltipSearcherSentCollaborationListCollapse}
        helpTooltipCollapseList={
          HlpTooltipSearcherSentCollaborationListCollapse
        }
        listColumnText={SearcherSentCollaborationListColumnText}
        // detail section system label
        detailHeadingText={SearcherSentCollaborationDetailHeadingText}
        toolTipExpandDetail={TooltipSearcherSentCollaborationDetailExpand}
        helpTooltipExpandDetail={
          HlpTooltipSearcherSentCollaborationDetailExpand
        }
        toolTipCollapseDetail={TooltipSearcherSentCollaborationDetailCollapse}
        helpTooltipCollapseDetail={
          HlpTooltipSearcherSentCollaborationDetailCollapse
        }
        detailsColumnText={SearcherSentCollaborationDetailColumnText}
        // selected user section system label
        userDetailHeadingText={SearcherSentCollaborationUserDetailHeaderText}
        toolTipExpandUserDetail={
          TooltipSearcherSentCollaborationUserDetailExpand
        }
        helpTooltipExpandUserDetail={
          HlpTooltipSearcherSentCollaborationUserDetailExpand
        }
        toolTipCollapseUserDetail={
          TooltipSearcherSentCollaborationUserDetailCollapse
        }
        helpTooltipCollapseUserDetail={
          HlpTooltipSearcherSentCollaborationUserDetailCollapse
        }
        userDetailsColumnText={SearcherSentCollaborationUserDetailColumnText}
        emptyCollaorationInfo={labels?.emptyCollaborationInfo}
        startDateLabel={SearcherSentCollaborationStartDateLabel}
        durationLabel={SearcherSentCollaborationDurationLabel}
        compensationLabel={SearcherSentCollaborationCompensationLabel}
        companyLabel={SearcherSentCollaborationCompanyLabel}
        descriptionLabel={SearcherSentCollaborationDescriptionLabel}
        acceptedCollaboration={SearcherSentCollaborationAcceptedLabel}
        selectInterest={SearcherSentCollaborationInterestOrNotLabel}
        onCollaborationDelete={this.handleCollaborationDelete}
        UserNameLabel={SearcherSentCollaborationUserNameLabel}
        UserPositionLabel={SearcherSentCollaborationPositionLabel}
        UserEmailLabel={SearcherSentCollaborationEmailLabel}
        UserPhoneLabel={SearcherSentCollaborationPhoneLabel}
        UserWebUrlLabel={SearcherSentCollaborationWebUrlLabel}
        collaborationDeletedMsg={InfoSearcherCollaborationDeleted}
        collaborationDeleteConfirmation={
          InfoSearcherSentCollaborationDeleteConfirmationMsg
        }
        TooltipSearcherCollaborationAddNewCollaboration={
          TooltipSearcherCollaborationAddNewCollaboration
        }
        helpTooltipSearcherCollaborationAddNewCollaboration={
          helpTooltipSearcherCollaborationAddNewCollaboration
        }
        SearcherCollaborationCreateNewTitle={
          SearcherCollaborationCreateNewTitle
        }
        popupYesText={collaborationDeleteCurtainYESBtnText}
        popupNoText={collaborationDeleteCurtainNOBtnText}
        listCollapsedTestId={"sent-collaboration-list-collapse-btn"}
        listCollapsedHelpTestId={"sent-collaboration-list-collapse-help-btn"}
        listExpandTestId={"sent-collaboration-list-expand-btn"}
        listExpandHelpTestId={"sent-collaboration-list-expand-help-btn"}
        detailCollapsedTestId={"sent-collaboration-detail-collapse-btn"}
        detailCollapsedHelpTestId={
          "sent-collaboration-detail-collapse-help-btn"
        }
        detailExpandTestId={"sent-collaboration-detail-expand-btn"}
        detailExpandHelpTestId={"sent-collaboration-detail-expand-help-btn"}
        userDetailCollapsedTestId={"sent-collaboration-ipro-collapse-btn"}
        userDetailCollapsedHelpTestId={
          "sent-collaboration-ipro-collapse-help-btn"
        }
        userDetailExpandTestId={"sent-collaboration-ipro-expand-btn"}
        userDetailExpandHelpTestId={"sent-collaboration-ipro-expand-help-btn"}
        searchInputTestId="search-input"
        locationProp={location}
      />
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    userId: UserId,
    IsSearcher: !IsFreelancer,
    IsFreelancer,
    isHelpActive,
  };
};

const actions = { notificationAction };
export default connect(mapStateToProps, actions)(NewSearcherCollaborationSent);
