import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import {
  Column,
  ConfirmDialog,
  Button,
  EmptyInfo,
  LoadingMask,
} from "../../common";
import ImgSrc from "../../assets/images/present.svg";

import PresentList from "./components/PresentList";
import PresentFormEditor from "./components/PresentFormEditor";
import {
  isValidURL,
  loadImageOrientation,
  isValidPhoneNumber,
} from "../../utilities/helpers";
import find from "lodash/find";
import filter from "lodash/filter";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import startCase from "lodash/startCase";
import includes from "lodash/includes";
import toLower from "lodash/toLower";

import {
  presentApi,
  presentDeleteApi,
  getResumesApi,
  getUserEmailsApi,
  getUserPhonenumbersApi,
  presentSubmitApi,
  updatePresentApi,
} from "./presentApi";
import { notificationAction } from "../../actions";
import "./present.scss";

import { onStateChangeAction } from "./presentAction";
import { Col, Row, Space, Input } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import PresentationFormNew from "./components/newForm";
import MobileFilter from "./components/mobileFilter.js";

const { Search } = Input;

class Present extends Component {
  state = {};

  componentDidMount() {
    this.props.isFetching && this.getPresentList();
    this.getResumes();
    this.getUserEmails();
    this.getUserPhonenumbers();
  }

  getResumes = () => {
    getResumesApi().then((data) => {
      const profiles = data.items.map((item) => ({
        ...item,
        value: item.ProfileId,
        label: item.ProfileValue,
      }));
      this.props.onStateChangeAction({ profiles });
    });
  };
  getUserEmails = () => {
    getUserEmailsApi().then((data) => {
      const filterEmail = data.items.filter((item) => item.UserEmailValue);
      const userEmails = filterEmail.map((item) => ({
        ...item,
        value: item.UserEmailValue,
        label: item.UserEmailValue,
      }));
      this.props.onStateChangeAction({
        userEmails,
      });
    });
  };
  getUserPhonenumbers = () => {
    getUserPhonenumbersApi().then((data) => {
      const filterPhone = data.items.filter(
        (item) => item.UserPhonenumberValue
      );
      const userPhonenumbers = filterPhone.map((item) => ({
        ...item,
        value: item.UserPhonenumberValue,
        label: item.UserPhonenumberValue,
      }));
      this.props.onStateChangeAction({
        userPhonenumbers,
      });
    });
  };

  getPresentList = () => {
    this.props.onStateChangeAction({ isFetching: true });
    presentApi()
      .then((data) => {
        let presentations = [];
        if (data.items.length > 0) {
          presentations = map(data.items, (presentation) => {
            const newItem = {
              ...presentation,
              selectedPhoneNumber: {
                ...presentation.UserPhonenumbers,
                value:
                  presentation.UserPhonenumbers &&
                  presentation.UserPhonenumbers.UserPhonenumberId,
                label:
                  presentation.UserPhonenumbers &&
                  presentation.UserPhonenumbers.UserPhonenumberValue,
              },
              IsDefault: presentation.IsDefault == true ? true : false,
              IsChecked: presentation.IsDefault == true ? true : false,
            };
            return newItem;
          });
        } else {
          presentations = [];
        }
        const currentPresentation =
          this.props.location.state &&
          presentations.find(
            (item) =>
              item.UserDetailId ===
              this.props.location.state.present.UserDetailId
          );
        const presentForm = isEmpty(currentPresentation)
          ? isEmpty(presentations)
            ? {}
            : head(presentations)
          : currentPresentation;
        this.props.onStateChangeAction({
          presentList: presentations,
          filteredList: presentations,
          isLoading: false,
          isFetching: false,
          presentForm,
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isFetching: false,
          isLoading: false,
        });
      });
  };

  handleCreateNewClick = () => {
    var { profiles } = this.props;
    if (profiles.length === 0) {
      const info = {
        message: this.props.labels.Present_ProfileNotFound,
        status: "info",
      };
      this.props.notificationAction(info);
    } else {
      const presentForm = { UserDetailId: -1 };
      this.props.onStateChangeAction({ presentForm });
    }
  };

  handlePresentClick = (id) => {
    var { presentList } = this.props;
    const presentForm = find(presentList, { UserDetailId: id });
    this.props.onStateChangeAction({
      presentForm,
    });
  };

  handleDeletePresent = (id) => {
    this.props.onStateChangeAction({
      deletedId: id,
      dialogMessage: this.props.labels.presentDeleteConfirmation,
    });
  };

  handleYesClick = () => {
    const { deletedId } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: "",
      isLoading: true,
    });
    presentDeleteApi({ id: deletedId })
      .then((data) => {
        if (data) {
          this.getPresentList();
          const info = {
            message: data.message,
            status: data.success ? "success" : "info",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            presentForm: {},
            deletedId: null,
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({
      dialogMessage: "",
      deletedId: null,
    });
  };

  handleFormSelectChange = (name, childName, selectedOption) => {
    if (!selectedOption) return;
    const { presentForm, userPhonenumbers } = this.props;
    const { value, UserPhonenumberId } = selectedOption;
    if (name === "UserPhonenumbers") {
      if (UserPhonenumberId) {
        this.props.onStateChangeAction({
          presentForm: {
            ...presentForm,
            selectedPhoneNumber: selectedOption,
            Phone: UserPhonenumberId,
            UserPhonenumberId,
          },
        });
        return;
      }
      const newPhoneNumber = head(userPhonenumbers);
      if (
        newPhoneNumber &&
        newPhoneNumber.value &&
        !isValidPhoneNumber(newPhoneNumber.value)
      ) {
        userPhonenumbers.splice(0, 1);
        this.props.onStateChangeAction({
          userPhonenumbers: [...userPhonenumbers],
        });
        return;
      } else {
        this.props.onStateChangeAction({
          presentForm: {
            ...presentForm,
            newPhoneCreated: true,
            Phone: value,
            selectedPhoneNumber: selectedOption,
          },
        });
        return;
      }
    }
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        [name]: {
          ...presentForm[name],
          ...selectedOption,
        },
        invalidRole: name === "Profiles" && !selectedOption.value,
      },
    });
  };

  handleSelectBlur = (e) => {
    const { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        invalidRole: presentForm && !presentForm.Profiles,
      },
    });
  };

  validateUrl = ({ name, value }) => {
    const { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        [name]: value,
        [`invalid${name}`]: !(value && isValidURL(value)),
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    const { presentForm } = this.props;
    if (name === "WebUrl") {
      this.validateUrl({ name, value });
      return;
    }
    if (name === "IsDefault") {
      this.props.onStateChangeAction({
        presentForm: {
          ...presentForm,
          [name]: !presentForm.IsDefault,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        [name]: value,
        [`invalid${name}`]: !value,
      },
    });
  };

  handleFormFieldBlur = (e) => {
    let { name, value } = e.target;
    const { presentForm } = this.props;
    if (name === "WebUrl") {
      if (
        !isEmpty(value) &&
        value.indexOf("http") < 0 &&
        value.indexOf("https") < 0 &&
        value.indexOf("ftp") < 0
      ) {
        value = "http://" + value;
        this.props.onStateChangeAction({
          presentForm: {
            ...presentForm,
            WebUrl: value,
          },
        });
      }
      this.validateUrl({ name, value });
      return;
    }
  };

  updateAvatar = (image) => {
    var { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        Logo: image,
      },
    });
  };

  handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      loadImageOrientation(e.target.files[0], this.updateAvatar);
      e.target.value = "";
    }
  };

  handleGetImgSrc = () => {
    const { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        Logo: null,
      },
    });
  };

  handleSubmitPresent = () => {
    const { presentForm } = this.props;
    let { WebUrl } = presentForm;
    const {
      Title,
      UserName,
      Detail,
      Profiles,
      UserEmails,
      UserDetailId,
      Logo,
      selectedPhoneNumber,
      IsDefault,
    } = presentForm;
    if (!UserName || !Profiles || !Title) {
      const { presentForm } = this.props;
      this.props.onStateChangeAction({
        presentForm: {
          ...presentForm,
          invalidTitle: !Title,
          invalidRole: !Profiles,
          invalidUserName: !UserName,
        },
      });
      const info = {
        message: this.props.labels.presentFixValidation,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    //WebURL Validation
    if (
      !isEmpty(WebUrl) &&
      WebUrl.indexOf("http") < 0 &&
      WebUrl.indexOf("https") < 0 &&
      WebUrl.indexOf("ftp") < 0
    ) {
      WebUrl = "http://" + WebUrl;
      this.props.onStateChangeAction({
        presentForm: {
          ...presentForm,
          WebUrl: WebUrl,
        },
      });
    }

    if (!isEmpty(WebUrl) && !isValidURL(WebUrl)) {
      const info = {
        message: this.props.labels.presentWebUrlInvalid,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    // //End WebURL Validation
    // const invalidUrls = filter(SocialLinks, (social) => {
    //   if (!isEmpty(social.URL) && !isValidURL(social.URL)) return social;
    // });

    // if (!isEmpty(invalidUrls)) {
    //   const invalidUrl = head(invalidUrls).Name;
    //   const info = {
    //     message: this.props.labels[`Invalid${startCase(invalidUrl)}Url`],
    //     status: "error",
    //   };
    //   this.props.notificationAction(info);
    //   return;
    // }
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        isSubmitting: true,
        invalidWebUrl: false,
      },
    });

    const presentObject = {
      Title,
      UserName,
      WebUrl,
      Detail,
      UserDetailId: UserDetailId,
      ProfileId: Profiles && Profiles.ProfileId,
      UserEmailId: UserEmails && UserEmails.UserEmailId,
      Email: UserEmails && UserEmails.UserEmailId,
      UserPhonenumberId:
        selectedPhoneNumber && selectedPhoneNumber.UserPhonenumberId,
      Logo,
      UserPhonenumberValue: selectedPhoneNumber && selectedPhoneNumber.value,
      IsDefault,
    };
    this.props.onStateChangeAction({ isLoading: true });
    //If UserDetailId = -1 its mean we are adding new presentation so we call post api.
    if (UserDetailId === -1) {
      presentSubmitApi(presentObject)
        .then((data) => {
          if (data.success) {
            this.getPresentList();
            const info = {
              message: this.props.labels.presentSuccessfullySaved,
              status: "success",
            };
            this.props.notificationAction(info);
          }
          const presentForm = { UserDetailId: -1 };
          this.props.onStateChangeAction({
            presentForm,
            isLoading: false,
          });
          this.setState((st) => ({ ...st, isMobileOpen: false }));
        })
        .catch(() => {
          this.setState((st) => ({ ...st, isMobileOpen: false }));
          this.props.onStateChangeAction({ isLoading: false });
        });
    }
    //If UserDetailId != 0  its mean we are updating presentation so we call put api.
    if (UserDetailId !== -1) {
      presentObject.Profiles = presentForm && presentForm.Profiles;
      presentObject.UserPhonenumbers =
        presentForm && presentForm.UserPhonenumbers;
      presentObject.UserEmails = presentForm && presentForm.UserEmails;
      presentObject.Email = presentObject.UserEmailId;
      presentObject.Phone = presentObject.UserPhonenumberId;
      updatePresentApi(presentObject)
        .then((data) => {
          if (data.success) {
            this.getPresentList();
            const info = {
              message: this.props.labels.presentSuccessfullySaved,
              status: "success",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({ isLoading: false });
          this.setState((st) => ({ ...st, isMobileOpen: false }));
        })
        .catch(() => {
          this.props.onStateChangeAction({ isLoading: false });
          this.setState((st) => ({ ...st, isMobileOpen: false }));
        });
    }
  };

  handlePresentationSearch = (e) => {
    const searchKey = e.target.value;
    const { presentList } = this.props;
    this.props.onStateChangeAction({
      searchKey,
    });
    const filteredList = filter(presentList, (presentation) => {
      if (includes(toLower(presentation.Title), toLower(searchKey))) {
        return presentation;
      }
    });
    this.props.onStateChangeAction({ filteredList });
  };

  render() {
    const {
      listCollapse,
      formCollapse,
      filteredList,
      presentForm,
      searchKey,
      dialogMessage,
      profiles,
      userEmails,
      userPhonenumbers,
      isLoading,
      isFetching,
    } = this.props;

    const { labels, isHelpActive } = this.props;
    const sytemLabel = labels;

    return (
      <PageWrapper className="present-page">
        {dialogMessage && (
          <ConfirmDialog testId="present-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="present-delete-yes"
                onClick={this.handleYesClick}
              >
                {labels.presentDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="present-delete-no"
                onClick={this.handleNoClick}
              >
                {labels.presentDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <MobileFilter
          isModalOpen={this.state.isMobileOpen}
          labels={this?.props?.labels}
          isLoading={isLoading}
          profiles={profiles}
          userEmails={userEmails}
          userPhonenumbers={userPhonenumbers}
          presentForm={presentForm}
          onFormFieldChange={this.handleFormFieldChange}
          onFormFieldBlur={this.handleFormFieldBlur}
          onSubmitPresent={this.handleSubmitPresent}
          onFileChange={this.handleFileChange}
          onSelectBlur={this.handleSelectBlur}
          onFormSelectChange={this.handleFormSelectChange}
          getImgSrc={this.handleGetImgSrc}
          handleSave={this.handleSubmitPresent}
          handleClose={() => {
            this.setState((st) => ({ ...st, isMobileOpen: false }));
          }}
        />
        {isLoading && <LoadingMask />}

        <Row className="new-search-resume-main">
          <Col xs={0} md={10} className="shortlist-lsit-section">
            <div className="h-100">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Search
                    enterButton="+"
                    onSearch={this.handleCreateNewClick}
                    size="large"
                    placeholder={labels?.presentSearchFieldPlaceholder}
                    onChange={this.handlePresentationSearch}
                    value={searchKey}
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    paddingRight: "6px",
                    overflow: "auto",
                  }}
                >
                  {filteredList?.length > 0 ? (
                    <Space
                      size={[6, 6]}
                      wrap
                      className="short-list-new company-list"
                    >
                      {filteredList.map((item) => (
                        <div
                          onClick={() =>
                            this.handlePresentClick(item.UserDetailId)
                          }
                          id={item?.UserDetailId}
                          key={item.UserDetailId}
                          className={`${
                            presentForm?.UserDetailId === item?.UserDetailId
                              ? "background-shortlist short-list-item"
                              : "short-list-item"
                          }`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex w-100 align-items-center">
                            <div
                              className={`dashItemImg ${
                                item.Logo ? "" : "no-dp"
                              }`}
                            >
                              <img
                                className="roundImg"
                                src={item.Logo ? item.Logo : ImgSrc}
                                alt={item.UserName}
                              />
                            </div>

                            <div className="CompNameCnt d-flex justify-content-between align-items-center">
                              <div>
                                <div
                                  className={
                                    item.UserDetailId
                                      ? "defaultCompanyName"
                                      : "listLabel"
                                  }
                                >
                                  {item.Title}
                                </div>
                                <div className="CompAddress">
                                  {item.UserName}
                                </div>
                                <div className="CompAddress">
                                  {(item?.Profiles &&
                                    item?.Profiles?.ProfileValue) ||
                                    ""}
                                </div>
                              </div>
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "18px",
                                }}
                                id={item?.UserDetailId}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDeletePresent(item?.UserDetailId);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </Space>
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={0} className="shortlist-lsit-section">
            <div className="h-100">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Search
                    enterButton="+"
                    onSearch={() => {
                      this.handleCreateNewClick();
                      this.setState((st) => ({ ...st, isMobileOpen: true }));
                    }}
                    size="large"
                    placeholder={labels?.presentSearchFieldPlaceholder}
                    onChange={this.handlePresentationSearch}
                    value={searchKey}
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    paddingRight: "6px",
                    overflow: "auto",
                  }}
                >
                  {filteredList?.length > 0 ? (
                    <Space
                      size={[6, 6]}
                      wrap
                      className="short-list-new company-list"
                    >
                      {filteredList.map((item) => (
                        <div
                          onClick={() => {
                            this.handlePresentClick(item.UserDetailId);
                            this.setState((st) => ({
                              ...st,
                              isMobileOpen: true,
                            }));
                          }}
                          key={item.UserDetailId}
                          id={item?.UserDetailId}
                          className={`${
                            presentForm?.UserDetailId === item?.UserDetailId
                              ? "background-shortlist short-list-item"
                              : "short-list-item"
                          }`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex w-100">
                            <div
                              className={`dashItemImg ${
                                item.Logo ? "" : "no-dp"
                              }`}
                            >
                              <img
                                className="roundImg"
                                src={item.Logo ? item.Logo : ImgSrc}
                                alt={item.UserName}
                              />
                            </div>

                            <div className="CompNameCnt d-flex justify-content-between align-items-center">
                              <div>
                                <div
                                  className={
                                    item.UserDetailId
                                      ? "defaultCompanyName"
                                      : "listLabel"
                                  }
                                >
                                  {item.Title}
                                </div>
                                <div className="CompAddress">
                                  {item.UserName}
                                </div>
                                <div className="CompAddress">
                                  {(item?.Profiles &&
                                    item?.Profiles?.ProfileValue) ||
                                    ""}
                                </div>
                              </div>
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "18px",
                                }}
                                id={item?.UserDetailId}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDeletePresent(item?.UserDetailId);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </Space>
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={14} xs={0} offset={0.5} className="h-100">
            <div className="column-secend">
              <PresentationFormNew
                labels={this?.props?.labels}
                isLoading={isLoading}
                profiles={profiles}
                userEmails={userEmails}
                userPhonenumbers={userPhonenumbers}
                presentForm={presentForm}
                onFormFieldChange={this.handleFormFieldChange}
                onFormFieldBlur={this.handleFormFieldBlur}
                onSubmitPresent={this.handleSubmitPresent}
                onFileChange={this.handleFileChange}
                onSelectBlur={this.handleSelectBlur}
                onFormSelectChange={this.handleFormSelectChange}
                getImgSrc={this.handleGetImgSrc}
                handleSave={this.handleSubmitPresent}
              />
            </div>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation, present }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;

  return { labels, user, isHelpActive, ...present };
};
const mapActionToProps = {
  onStateChangeAction,
  notificationAction,
};
export default connect(mapStateToProps, mapActionToProps)(Present);
