import React from "react";
import "./resumeList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Checkbox, Col, Dropdown, Grid, Row, Typography } from "antd";
import { EmptyInfo, LoadingMaskRow } from "..";
import { join } from "lodash";
import { Tooltip } from "../../common";
// import StarIcon from '../../assets/images/Icons/star.png'
import SelectedIcon from '../../assets/images/Icons/resume-select.svg'
import LocationIcon from '../../assets/images/location.png'
import ClampLines from "react-clamp-lines";

const { useBreakpoint } = Grid;
const { Title } = Typography

const ResumeListComponentNewDesign = ({
    resumeList,
    score,
    state,
    fetchMoreResumes,
    selected,
    setSelected,
    setShowModal,
    shortListDropdown,
    opportunityDropdown,
    label,
    name,
}) => {
    const screens = useBreakpoint();
    const renderValue = (resume) => {
        const ProfileValue = resume.map((item) => item.ProfileValue);
        return join(ProfileValue, ", ");
    };

    return (
        <div className="resume-list-component">
            {resumeList?.length > 0 && (
                <InfiniteScroll
                    dataLength={resumeList?.length}
                    hasMore={state?.hasMore}
                    next={fetchMoreResumes}
                    loadMore={fetchMoreResumes}
                    loader={<LoadingMaskRow />}
                    scrollThreshold="100px"
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    }}
                    height={"100%"}
                >
                    {resumeList?.map((single, index) => (
                        <div
                            className="card-resume pointer p-0 d-flex"
                        >
                            <div style={{ width: "45px" }} className="column-checkbox">
                                <Checkbox
                                    value={single?.ResumeId}
                                    checked={selected?.checked?.includes(single?.ResumeId)}
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        if (e.target.checked == true) {
                                            setSelected((st) => ({
                                                ...st,
                                                checked: [...st.checked, e.target.value],
                                            }));
                                        } else {
                                            const filter = selected?.checked?.filter(
                                                (x) => x != e.target.value
                                            );
                                            setSelected((st) => ({
                                                ...st,
                                                checked: filter,
                                            }));
                                        }
                                    }}
                                    style={{
                                        fontSize: "large",
                                    }}
                                />
                            </div>
                            <div style={{ padding: "14px", flex: 1 }}
                            >
                                <Row gutter={16}>
                                    <Col md={20} sm={18} xs={24}
                                        onClick={() => {
                                            setShowModal((st) => ({
                                                ...st,
                                                data: single,
                                                show: true,
                                            }));
                                        }}
                                    >
                                        <Title level={5} className="m-0">
                                            <ClampLines
                                                text={renderValue(single?.Profiles)}
                                                lines={1}
                                                buttons={false}
                                            />
                                        </Title>
                                    </Col>
                                    {/* <Col md={8} xs={8}>
                                        <div className="star-icons d-flex align-items-center">
                                            <Title
                                                level={5}
                                                className="m-0"
                                                style={{ fontWeight: 400 }}
                                            >
                                                {single?.CorrelationScore}/{score}
                                            </Title>
                                            <div>
                                                {" "}
                                                <img src={StarIcon} alt="" />
                                            </div>
                                        </div>
                                    </Col> */}
                                    {name !== "shortlist" && (
                                        <Col sm={6} md={4} xs={0}>
                                            <Tooltip followCursor={true} content={"Create shortlist"}>
                                                <div>
                                                    <Dropdown
                                                        overlay={shortListDropdown(single)}
                                                        trigger={["click"]}
                                                    >
                                                        <div
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <img src={SelectedIcon} alt="" />
                                                        </div>
                                                    </Dropdown>
                                                </div>
                                            </Tooltip>
                                        </Col>
                                    )}
                                </Row>
                                {single?.Region && (
                                    <div className="location-main mt-1"
                                        onClick={() => {
                                            setShowModal((st) => ({
                                                ...st,
                                                data: single,
                                                show: true,
                                            }));
                                        }}
                                    >
                                        <div>
                                            <img src={LocationIcon} alt="" />
                                        </div>
                                        <p className="location">{single?.Region || ""}</p>
                                    </div>
                                )}
                                <div gutter={[8, 8]} className="mt-2 d-flex gap-8 justify-content-between align-items-center"
                                    style={{ flexWrap: screens?.xs ? "wrap" : "unset" }}
                                >
                                    <div xs={24} sm={24} md={20}
                                        onClick={() => {
                                            setShowModal((st) => ({
                                                ...st,
                                                data: single,
                                                show: true,
                                            }));
                                        }}
                                        style={{ width: screens?.xs ? "100%" : "unset" }}
                                    >
                                        <div className="resume-btn">
                                            {single?.Skills?.slice(0, 3)?.map((row, index) => (
                                                <button className="btn"> {row?.SkillValue}</button>
                                            ))}
                                            {single?.Skills?.length > 3 && (
                                                <button className="btn">
                                                    {`+ ${single?.Skills?.length - 3}`}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {name !== "shortlist" && (screens?.xs) && (
                                        <div xs={4} md={0}>
                                            <Tooltip followCursor={true} content={"Create shortlist"}>
                                                <div>
                                                    <Dropdown
                                                        overlay={shortListDropdown(single)}
                                                        trigger={["click"]}
                                                    >
                                                        <div
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <img src={SelectedIcon} alt="" />
                                                        </div>
                                                    </Dropdown>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                    <div xs={18} sm={14} md={4}>
                                        <Dropdown
                                            overlay={opportunityDropdown(single)}
                                            trigger={["click"]}
                                            className="dropdown-opportunity"
                                        >
                                            <div className="search-btn">
                                                <button className="btn2 w-100" style={{ lineHeight: "24px", whiteSpace: "pre" }}>
                                                    Invite to opportunity
                                                </button>
                                            </div>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            )}
            {resumeList?.length == 0 && (
                <EmptyInfo>{label || "No Resume found"}</EmptyInfo>
            )}
        </div>
    );
};

export default ResumeListComponentNewDesign;
