import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Typography, Select } from "antd";
import classes from "./index.module.scss";
import { getRolesApi } from "../../dashboardApi";
 import DebounceSelect from "../../../../components/DebounceSelect/DebounceSelect";
import { RoleProficiencyList } from "../../../../utilities";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import LanguageImage from '../../../../assets/images/role-popup.png'
function RolePopup({
  isLoading,
  SaveResumeDetails,
  errorMessage,
  labels,
  handleRoleChange,
  currentRole,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => { }, []);

  const onFinish = () => {
    SaveResumeDetails();
    setIsModalVisible(false);
  };

  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.ResumeEditHeadingRole}</button>

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
         className={`new-design-modal ${classes.popUpForm}`}
      >
           <div className="text-center mb-3 header-modal">
          <h5>{labels.ResumeEditHeadingRole}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select your role and your role level</p>
        </div>
        <Form
          name="normal_login"
          className={`${classes.form}  form`}
          onFinish={onFinish}
          size="large"
        // initialValues={{ date: SkillProficiencyList[0] }}
        >
          <Form.Item
            className={`${classes.customSelect} `}
            name="role"
            rules={[
              {
                required: currentRole.ProfileId ? false : true,
                message: labels.SELECT_ROLE_VALIDATION_LBL,
              },
            ]}
          >
            <DebounceSelect
              mode="multiple"
              selectName={"ProfileId"}
              value={currentRole.KeywordId}
              placeholder={labels.SELECT_ROLE_LBL}
              name="role"
              fetchOptions={getRolesApi}
              handleChange={handleRoleChange}
            />
          </Form.Item>
          <Form.Item
             name="ExperienceLevel"
            rules={[
              {
                required: currentRole.ExperienceLevel ? false : true,
                message: labels.SELECT_EXPERIECE_LEVEL_VALIDATION_LBL,
              },
            ]}
            className={`${classes.date_input} ${classes.customSelect}`}
          >
            <Select
              placeholder={labels.IPRO_DASHBOARD_RESUME_LANGUAGE_LEVEL_LABEL}
              onChange={(e) => handleRoleChange("ExperienceLevel", e)}
              allowClear
              name={"ExperienceLevel"}
              autoComplete="new-password"
            >
              {RoleProficiencyList &&
                RoleProficiencyList.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-0 my-2 mb-3"
            loading={isLoading}
          >
            {labels.Done_LBL}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default RolePopup;
