import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Alert,
} from "antd";
import classes from "./index.module.scss";
import { getKeywordApi } from "../../dashboardApi";
import DebounceSelect from "../../../../components/DebounceSelect/DebounceSelect";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import LanguageImage from '../../../../assets/images/keywork-popup.png'

function KeywordPopup({
  isLoading,
  SaveResumeDetails,
  errorMessage,
  labels,
  handleKeywordChange,
  currentKeyword
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => { }, []);

  const onFinish = () => {
    SaveResumeDetails()
    setIsModalVisible(false);
  };

  return (
    <>
      <button
        className="add-profile"
        onClick={() => {
          setIsModalVisible(true)
        }}
      >{labels.ResumeHeadingKeyword}</button>
      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className={`new-design-modal ${classes.popUpForm}`}

      >
        <div className="text-center mb-3 header-modal">
          <h5>{labels.ResumeHeadingKeyword}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select keyword related to your skill</p>
        </div>
        <Form
          name="normal_login"
          className={`${classes.form} form`}
          onFinish={onFinish}
          size="large"
        // initialValues={{ date: SkillProficiencyList[0] }}
        >
          <Form.Item
            className={`${classes.customSelect}`}
            name="keyword"
            rules={[
              {
                required: currentKeyword.KeywordId ? false : true,
                message: labels.SELECT_KEYWORD_VALIDATION_LBL,
              },
            ]}
          >
            <DebounceSelect
              mode="multiple"
              selectName={"KeywordId"}
              value={currentKeyword.KeywordId}
              placeholder={labels.SELECT_KEYWORD_LBL}
              name="keyword"
              fetchOptions={getKeywordApi}
              handleChange={handleKeywordChange}
            />
          </Form.Item>

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            Done
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default KeywordPopup;
