import React, { Component } from "react";
import { connect } from "react-redux";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import { PageWrapper } from "../../../../components";
import { notificationAction } from "../../../../actions";
import { Messages } from "../../../../utilities";
import toLower from "lodash/toLower";
import { LoadingMask, ConfirmDialog, EmptyInfo } from "../../../../common";
import "./opportunity.scss";
import {
  getAllOpportunityApi,
  retrieveResumeSearchApi,
  declinedOpportunityApi,
  deleteOpportunityApi,
  getSocialMediaApi,
  opportunityRestoreApi,
  getAllPresenationApi,
  readOpportunityApi,
  getCurrenciesApi,
} from "./opportunityApi";
import { unreadOpportunitiesAction } from "../../../../actions/navigationActions";
import { onStateChangeAction } from "./opportunityAction";
import { Col, Row, Select } from "antd";
import MenuDots from "../../../../assets/newResume/menuDots";
import NewOpportunityDetailIpro from "./components/NewOpportunityDetail";
import CompanyDetailNew from "../../../../common/companyDetail/companyDetail";
import PresentationView from "./components/presentationView";
import CompanyDetailMobileModal from "./components/companyDetailMobile";
import PresentationMobileModal from "./components/presentationViewMobile";
const { Option } = Select;

class OpportunityNew extends Component {
  state = {
    opportunity: [],
    selected: null,
    loading: false,
    companyModal: false,
    windowWidth: window.innerWidth,
  };
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    this.props.onStateChangeAction({
      opportunityList: [],
      filteredOpportunity: [],
      fetchingOpportunity: false,

      deletedId: null,
      requestName: "",
      selectedOpportunity: {},
      selectedCompany: {},
      showPresentationDetail: false,
    });
    const { isAcceptedView, isDeclinedView } = this.props;
    window.actionName = isAcceptedView
      ? "accepted"
      : isDeclinedView
      ? "declined"
      : "new";
    this.props.onStateChangeAction({
      companyPresentationHeadingText: this.props.labels
        .iproOpportunityNewCompanyDetailHeading,
    });
    this.getSocialMedia();
    this.getAllOpportunity();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getSocialMedia = () => {
    getSocialMediaApi().then((data) => {
      if (data.success) {
        this.props.onStateChangeAction({ socialMedia: data.items });
      }
    });
  };

  getAllOpportunity = () => {
    this.props.onStateChangeAction({ isLoading: true });
    const { url, isAccepted, opportunityType, currencies } = this.props;
    let currenciesList = [];

    if (currencies && currencies.length && currencies.length > 0) {
      currenciesList = currencies;
    } else {
      getCurrenciesApi()
        .then((res) => {
          if (res.success) {
            currenciesList = res.items;
          }
        })
        .catch((err) => console.log("Err ", err));
    }

    this.props.onStateChangeAction({ fetchingOpportunity: true });
    getAllOpportunityApi({ url })
      .then((data) => {
        let allOpportunity = [];
        if (data.success) {
          allOpportunity = map(data.items[opportunityType], (opp) => {
            if (!isNaN(opp.FeeCurrencyType) && currenciesList.length > 0) {
              opp.FeeCurrencyType = currenciesList.find(
                (a) => a.CurrencyId == opp.FeeCurrencyType
              ).Name;
            }
            return {
              ...opp,
              label: opp?.RequestName,
              value: opp?.RequestResumeId,
            };
          });

          this.props.onStateChangeAction({
            opportunityList: allOpportunity,
            filteredOpportunity: allOpportunity,
            fetchingOpportunity: false,

            deletedId: null,
            requestName: "",
            selectedOpportunity: {},
            showPresentationDetail: false,
          });
          this.handleOpportunitySelect(allOpportunity?.[0]);
          if (
            opportunityType === "NewRequests" &&
            data.items.NewRequests.length > 0
          ) {
            const spliturl = this.props.locationProp.pathname.match(/\d+/g);
            if (spliturl != null) {
              let filterrequestid = data.items.NewRequests.filter(
                (opportunity) => opportunity.RequestId == spliturl[0]
              );
              this.setState({
                opportunity: filterrequestid[0],
              });
              this.handleOpportunitySelect(this.state.opportunity);
            }
          }
          if (
            this.props.locationProp.state &&
            this.props.locationProp.state.visitRequestId
          ) {
            const visitOpportunity = filter(this.props.filteredOpportunity, [
              "RequestId",
              this.props.locationProp.state.visitRequestId,
            ]);
            this.props.onStateChangeAction({
              selectedCompany: visitOpportunity[0].Company,
              selectedOpportunity: visitOpportunity[0],
            });
            this.retrieveResumeSearch(this.props.selectedOpportunity);
          }
          this.props.onStateChangeAction({ isLoading: false });
        } else {
          this.props.onStateChangeAction({
            fetchingOpportunity: false,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunityList } = this.props;
    const filteredOpportunity = filter(opportunityList, (opp) =>
      includes(toLower(opp.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filteredOpportunity, searchKey: value });
  };

  handleDeleteOpportunityNew = (obj) => {
    this.props.onStateChangeAction({
      deletedId: obj.RequestId,
      requestName: obj.RequestName,
      dialogMessage: this.props.labels.iproInvitationDeletionConfirmation,
    });
  };

  handleYesClick = (event) => {
    const { deletedId, requestName } = this.props;
    this.props.onStateChangeAction({ dialogMessage: "" });
    this.props.onStateChangeAction({ isLoading: true });

    const obj = new Object();
    obj.IsRequestToDelete = false;
    obj.RequestId = deletedId;
    obj.RequestName = requestName;
    obj.ResumeId = 0;
    deleteOpportunityApi(obj)
      .then((data) => {
        if (data) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.iproInvitationSuccessfullyDeleted,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            selectedOpportunity: {},
            showPresentationDetail: false,
            showCompanyDetails: false,
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };

  handleOpportunitySelect = (selectedOpportunity) => {
    this.setState((st) => ({ ...st, selected: selectedOpportunity?.value }));
    this.props.onStateChangeAction({
      selectedOpportunity,
      selectedCompany: selectedOpportunity.Company,
      showPresentationDetail: false,
      showCompanyDetails: true,
    });
    if (!selectedOpportunity.IsRead) {
      readOpportunityApi({
        requestResumeId: selectedOpportunity.RequestResumeId,
      }).then((response) => {
        if (response.success) {
          const { opportunityList } = this.props;
          const {
            isAcceptedView,
            isDeclinedView,
            isNewOpportunityView,
          } = this.props;
          const opportunities = filter(
            opportunityList,
            (item) =>
              item.RequestId !== selectedOpportunity.RequestId &&
              item.RequestResumeId !== selectedOpportunity.RequestResumeId &&
              !item.IsRead
          );
          if (isNewOpportunityView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: { newOpportunities: opportunities.length },
            });
          } else if (isAcceptedView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: {
                acceptedOpportunities: opportunities.length,
              },
            });
          } else if (isDeclinedView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: {
                declinedOpportunities: opportunities.length,
              },
            });
          }
          const filteredOpportunity = map(opportunityList, (oppo) => {
            if (oppo.RequestId === selectedOpportunity.RequestId) {
              oppo.IsRead = true;
              return oppo;
            }
            return oppo;
          });
          this.props.onStateChangeAction({ filteredOpportunity });
        }
      });
    }

    if (this.props.isAccepted && selectedOpportunity.SavedSearchedId) {
      //find
      this.retrieveResumeSearch(selectedOpportunity);
    }
  };

  retrieveResumeSearch = (selectedOpportunity) => {
    retrieveResumeSearchApi(selectedOpportunity.SavedSearchedId).then(
      (data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            selectedOpportunity: {
              ...selectedOpportunity,
              retrieveResumeSearch: {
                Profiles: data.items.filter((a) => a.LookupTypeId == 1),
                Skills: data.items.filter((a) => a.LookupTypeId == 2),
              },
            },
          });
        }
      }
    );
  };

  //Restore POPUP -start
  handleOpportunityRestore = () => {
    this.props.onStateChangeAction({
      restoreDialogMessage: this.props.labels.oppRestorationConfiramtion,
    });
  };

  restoreHandleYesClick = () => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({ isLoading: true });

    opportunityRestoreApi(selectedOpportunity.RequestResumeId)
      .then((data) => {
        if (data) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.IproOpportunityDeclinedRestoreMessage,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            restoreDialogMessage: "",
            showPresentationDetail: false,
            showCompanyDetails: false,
            selectedOpportunity: {},
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
        this.setState((st) => ({ ...st, selected: null }));
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  restoreHandleNoClick = () => {
    this.props.onStateChangeAction({
      restoreDialogMessage: "",
      deletedId: null,
    });
  };

  //Restore POPUP -end
  handleDeleteOpportunity = (obj) => {
    this.props.onStateChangeAction({
      deletedId: obj.RequestId,
      requestName: obj.RequestName,
      dialogMessage: this.props.opportunityDeletedMsg,
    });
  };

  handleOpportunityDetailAcceptClick = () => {
    this.getAllPresentation();
  };
  handleOpportunityDetailDeclinedClick = () => {
    this.setState((st) => ({ ...st, selected: null }));
    this.props.onStateChangeAction({
      showPresentationDetail: false,
      showCompanyDetails: true,
    });
    const obj = new Object();
    obj.IsRequestToDelete = false;
    obj.RequestId = this.props.selectedOpportunity.RequestId;
    obj.ResumeId = 0;
    obj.RequestName = this.props.selectedOpportunity.RequestName;
    this.declinedOpportunity(obj);
  };

  declinedOpportunity = (info) => {
    this.props.onStateChangeAction({ isLoading: true });
    declinedOpportunityApi(info)
      .then((data) => {
        if (data.success) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.iproOppNewDeclinedMessage,
            status: "success",
          };
          this.props.notificationAction(info);

          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            selectedOpportunity: {},
            showPresentationDetail: false,
            showCompanyDetails: false,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: true });
      });
  };

  handleOpportunityDetailCompanyClick = () => {
    this.props.onStateChangeAction({
      showPresentationDetail: false,
      showCompanyDetails: true,
      companyPresentationHeadingText: this.props.labels
        .iproOpportunityNewCompanyDetailHeading,
    });
  };

  getAllPresentation = () => {
    this.setState((st) => ({ ...st, loading: true }));
    getAllPresenationApi()
      .then((data) => {
        if (data.success) {
          if (data.items.length > 0) {
            this.props.onStateChangeAction({
              showPresentationDetail: true,
              showCompanyDetails: false,
              companyPresentationHeadingText: this.props.labels
                .IproOpportunityNewUserDetailHeaderText,
            });
            if (this?.state?.windowWidth < 768) {
              this.setState((st) => ({ ...st, presentaionModal: true }));
            }
          } else {
            const info = {
              message: this.props.labels
                .OPPORTUNITY_PRESENT_NOT_AVAILABLE_MESSAGE,
              status: "info",
            };
            this.props.notificationAction(info);
          }
          this.setState((st) => ({ ...st, loading: false }));
        }
      })
      .catch(() => {
        this.setState((st) => ({ ...st, loading: false }));
      });
  };

  render() {
    const {
      searchInputPlaceholder,
      emptyOpportunityInfo,
      selectInterest,
      companyAddressLabel,
      companyIndustryLabel,
      companyCountryLabel,
      companyPhoneLabel,
      companyWebUrlLabel,
      companyVatLabel,
      IsSearcher,
      locationsLabel,
      startDateLabel,
      durationLabel,
      companyLabel,
      descriptionLabel,
      countryLabel,

      SearchParameterHeading,
      Lbltitle,
      LblProfile,
      LblSkills,
      LblKeyword,
      LblIndustry,
      LblCertification,
      LblCountry,
      LblLanguage,
      LblInterest,
      LblRestore,
      BtnAccept,
      BtnDecline,
      BtnRestore,
      isAccepted,
      opportunityType,
      labels,
    } = this.props;
    const {
      selectedOpportunity,
      filteredOpportunity,
      isLoading,
      selectedCompany,
      socialMedia,
      showPresentationDetail,
      showCompanyDetails,
      dialogMessage,
      restoreDialogMessage,
    } = this.props;

    return (
      <PageWrapper className="opportunity">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="opportunityIPro-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button
                className="dialog-btn"
                test-data-id="opportunityIPro-delete-yes"
                onClick={this.handleYesClick}
              >
                {Messages.feedback.confirm.Yes}
              </button>
              <button
                className="dialog-btn"
                test-data-id="opportunityIPro-delete-no"
                onClick={this.handleNoClick}
              >
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        {restoreDialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {restoreDialogMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button
                className="dialog-btn"
                onClick={this.restoreHandleYesClick}
              >
                {Messages.feedback.confirm.Yes}
              </button>
              <button
                className="dialog-btn"
                onClick={this.restoreHandleNoClick}
              >
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <CompanyDetailMobileModal
          isModalOpen={this?.state?.companyModal}
          setIsModalOpen={() => {
            this.setState((st) => ({ ...st, companyModal: false }));
          }}
          selectedCompany={selectedCompany}
          addressLabel={companyAddressLabel}
          industryLabel={companyIndustryLabel}
          countryLabel={companyCountryLabel}
          phoneLabel={companyPhoneLabel}
          webUrlLabel={companyWebUrlLabel}
          vatLabel={companyVatLabel}
        />
        <PresentationMobileModal
          isModalOpen={this?.state?.presentaionModal}
          setIsModalOpen={() => {
            this.setState((st) => ({ ...st, presentaionModal: false }));
          }}
          selectedOpportunityNew={selectedOpportunity}
          showCompanyDetails={showCompanyDetails}
          showPresentationDetail={showPresentationDetail}
          loading={this.state.loading}
          labels={labels}
          socialMedia={socialMedia}
          getAllOpportunity={this.getAllOpportunity}
        />
        <Row className="new-search-resume-main">
          <Col
            xs={24}
            md={Object.keys(selectedOpportunity).length == 0 ? 24 : 10}
            className="h-100"
          >
            <div className="h-100 flex flex-column">
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <Col xs={22} md={24}>
                  <Select
                    style={{
                      width: "100%",
                      overflow: "auto",
                    }}
                    placeholder={searchInputPlaceholder}
                    size="large"
                    optionLabelProp="label"
                    value={this?.state?.selected}
                    onChange={(e) => {
                      const filterOption = filteredOpportunity?.find(
                        (row) => row?.value == e
                      );
                      this.handleOpportunitySelect(filterOption);
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {filteredOpportunity?.map((task, index) => {
                      return (
                        <Option
                          key={index}
                          value={task.value}
                          label={task.label}
                        >
                          <div className="align-items-center d-flex w-100 justify-content-between">
                            <span
                              style={{
                                paddingRight: "6px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                color: !task?.IsRead ? "#f6aa3a" : "",
                              }}
                            >
                              {task.label}
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col
                  md={0}
                  xs={2}
                  onClick={() => {
                    if (selectedCompany?.UserCompanyId) {
                      this.setState((st) => ({
                        ...st,
                        companyModal: true,
                      }));
                    }
                  }}
                >
                  <MenuDots style={{ float: "right" }} />
                </Col>
              </Row>
              <div
                style={{
                  marginTop: "15px",
                  overflow: "auto",
                  paddingRight: "6px",
                }}
              >
                <NewOpportunityDetailIpro
                  selectedOpportunity={selectedOpportunity}
                  emptyOpportunityInfo={emptyOpportunityInfo}
                  locationsLabel={locationsLabel}
                  startDateLabel={startDateLabel}
                  SearcherOpportunitySentDetailFee={
                    labels.SearcherOpportunitySentDetailFee
                  }
                  durationLabel={durationLabel}
                  companyLabel={companyLabel}
                  descriptionLabel={descriptionLabel}
                  countryLabel={countryLabel}
                  SearchParameterHeading={SearchParameterHeading}
                  Lbltitle={Lbltitle}
                  LblProfile={LblProfile}
                  LblSkills={LblSkills}
                  LblKeyword={LblKeyword}
                  LblIndustry={LblIndustry}
                  LblCertification={LblCertification}
                  LblCountry={LblCountry}
                  LblInterest={LblInterest}
                  LblRestore={LblRestore}
                  BtnAccept={BtnAccept}
                  BtnDecline={BtnDecline}
                  BtnRestore={BtnRestore}
                  LblLanguage={LblLanguage}
                  selectInterest={selectInterest}
                  handleOpportunityDetailAcceptClick={
                    this.handleOpportunityDetailAcceptClick
                  }
                  handleOpportunityDetailDeclinedClick={
                    this.handleOpportunityDetailDeclinedClick
                  }
                  handleOpportunityDetailCompanyClick={
                    this.handleOpportunityDetailCompanyClick
                  }
                  IsSearcher={IsSearcher}
                  opportunityType={opportunityType}
                  showPresentationDetail={showPresentationDetail}
                  showCompanyDetails={showCompanyDetails}
                  onOpportunityRestore={this.handleOpportunityRestore}
                  isAccepted={isAccepted}
                />
              </div>
            </div>
          </Col>
          <Col
            md={14}
            xs={0}
            offset={0.5}
            className={
              Object.keys(selectedOpportunity).length == 0 ? null : "h-100"
            }
          >
            {showCompanyDetails && selectedCompany?.UserCompanyId && (
              <CompanyDetailNew
                selectedCompany={selectedCompany}
                addressLabel={companyAddressLabel}
                industryLabel={companyIndustryLabel}
                countryLabel={companyCountryLabel}
                phoneLabel={companyPhoneLabel}
                webUrlLabel={companyWebUrlLabel}
                vatLabel={companyVatLabel}
              />
            )}
            {showPresentationDetail && (
              <PresentationView
                selectedOpportunityNew={selectedOpportunity}
                showCompanyDetails={showCompanyDetails}
                showPresentationDetail={showPresentationDetail}
                loading={this.state.loading}
                labels={labels}
                socialMedia={socialMedia}
                getAllOpportunity={this.getAllOpportunity}
                handleOk={() => {}}
              />
            )}
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

//export default Opportunity;
const mapStateToProps = (
  {
    systemLabel,
    iproOpportunityNew,
    iproOpportunityAccepted,
    iproOpportunityDeclined,
    currency,
  },
  { isAcceptedView, isDeclinedView }
) => {
  const { labels } = systemLabel;
  const { currencies } = currency;
  const opportunityProps = isAcceptedView
    ? iproOpportunityAccepted
    : isDeclinedView
    ? iproOpportunityDeclined
    : iproOpportunityNew;
  return { labels, currencies, ...opportunityProps };
};

export default connect(mapStateToProps, {
  notificationAction,
  unreadOpportunitiesAction,
  onStateChangeAction,
})(OpportunityNew);
