import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import {
  CalendarOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { onStateChangeAction } from "../../searchAction";
import { notificationAction } from "../../../../actions";
import CommonSelect from "../../../../common/selectFilter/selectFilter";
import '../../newDesignSearch.scss'
const { Text } = Typography
const MobileFilter = ({
  isModalOpen,
  setIsModalOpen,
  filter,
  handleChange,
  handleSearch,
  globalState,
  loading,
  setCreateSaveSearchModal,
  labels,
  state,
  handleExistingSaveSearchClick,
  handleClear,
}) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const saveSearchDropdown = (resume) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          onClick={(e) => {
            setCreateSaveSearchModal((st) => ({
              ...st,
              open: true,
              id: 0,
            }));
            setShowDropdown(false);
          }}
        >
          <PlusOutlined />
          <span className="span-">Create New Search</span>
        </Menu.Item>
        <Menu.Divider />
        {state?.saveSearch?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              handleExistingSaveSearchClick(single?.SavedSearchId);
              setShowDropdown(!showDropdown);
            }}
          >
            {single?.SearchName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        style={{ top: 20 }}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        < div className="mt-2 filters-new">
             <CommonSelect
              loading={loading?.role}
              options={filter?.roles}
              label={"Role"}
              value={globalState?.selected?.ProfileIds}
              placeholder={"Select role"}
              handleChange={handleChange}
              handleSearch={handleSearch}
              searchParams={"role"}
              changesParams={"ProfileIds"}
            />
         
            <CommonSelect
              options={filter?.location}
              label={"Location"}
              placeholder={"Select location"}
              value={globalState?.selected?.Countries}
              handleChange={handleChange}
              searchParams={"role"}
              changesParams={"Countries"}
              search={false}
            />
       
            <CommonSelect
              options={filter?.language}
              label={"Language"}
              value={globalState?.selected?.Languages}
              placeholder={"Select Language"}
              search={false}
              handleChange={handleChange}
              changesParams={"Languages"}
            />
      
            <CommonSelect
              options={filter?.skills}
              label={"Skill"}
              loading={loading?.skill}
              value={globalState?.selected?.SkillIds}
              placeholder={"Select skills"}
              handleChange={handleChange}
              changesParams={"SkillIds"}
              handleSearch={handleSearch}
              searchParams={"Skill"}
            />
        
            <CommonSelect
              options={filter?.keywords}
              label={"Keyword"}
              loading={loading?.keyword}
              value={globalState?.selected?.KeywordIds}
              placeholder={"Select keywords"}
              handleChange={handleChange}
              changesParams={"KeywordIds"}
              handleSearch={handleSearch}
              searchParams={"keyword"}
            />
     
            <CommonSelect
              options={filter?.industry}
              label={"Industry"}
              loading={loading?.industry}
              value={globalState?.selected?.IndusteryIds}
              placeholder={"Select industry"}
              handleChange={handleChange}
              changesParams={"IndusteryIds"}
              handleSearch={handleSearch}
              searchParams={"industry"}
            />
       
            <CommonSelect
              options={filter?.certification}
              label={"Certification"}
              loading={loading?.certificate}
              value={globalState?.selected?.CertificationIds}
              placeholder={"Select certifications"}
              handleChange={handleChange}
              changesParams={"CertificationIds"}
              handleSearch={handleSearch}
              searchParams={"certificate"}
            />
     
            <Text className="label m-0">Availability Date</Text>
            <div className="animated">
              <DatePicker
                style={{ width: "100%", marginTop: "6px" }}
                value={globalState?.selected?.AvailabilityDate}
                onChange={(e) => handleChange(e, "AvailabilityDate")}
                bordered={false}
              />
            </div>
      

          <Col xs={24} className="mt-2">
            <div className="search-btn">
              <button className="btn1">Clear Search</button>
              <button className="btn2">Save Search</button>
            </div>
          </Col>
        </div>
      </Modal>
    </>
  );
};
export default MobileFilter;
