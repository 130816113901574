import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import { PageWrapper } from "../../../components";
import { LoadingMaskRow, LoadingMask, ConfirmDialog } from "../../../common";
import "./collaboration.scss";
import {
  getAllCollaborationApi,
  updateCollaborationApi,
  readCollaborationApi,
  getCurrenciesApi,
  getCollaborationReviewApi,
  getCollaborationReasonsApi,
  getReviewQuestions,
  submitCollaborationReview,
  deleteCollaborationApi,
  // collaborationReviewApi,
} from "./collaborationApi";
import { unreadCollaborationsAction } from "../../../actions/navigationActions";
import CompanyDetail from "../../Company/components/CompanyDetail";
import { onStateChangeAction } from "./collaborationAction";
import toLower from "lodash/toLower";
import { isNullOrUndefined } from "util";
import { Col, Row, Select } from "antd";
import NewCollaborationDetail from "../NewSearcher/components/common/NewCollaborationDetail";
import NewUserDetail from "./components/newUserDetail";
import MenuDots from "../../../assets/newResume/menuDots";
import moment from "moment";
import NewRatingView from "./components/newRatingView";
import UserDetailModal from "./components/mobileModal";
import CompanyDetailNew from "../../../common/companyDetail/companyDetail";

const { Option } = Select;
class NewCollaboration extends Component {
  state = {
    fetchingReviews: false,
    isDeleteCollaboration: false,
    CollborationCloseReasonId: null,
    windowWidth: window.innerWidth,

    mobileModal: false,
    options: [
      { value: 1, label: "Job completed successfully" },
      { value: 2, label: "Job cancelled due to freelancer performance" },
      { value: 3, label: "Job canceled for other reasons" },
      { value: 4, label: "Another Reason" },
    ],
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    this.props.onStateChangeAction({
      selectedCollaboration: {},
    });
    const {
      isIproActiveCollaboration,
      isIproInActiveCollaboration,
      isSentView,
      isAcceptedView,
      isDeclinedView,
      isInActiveView,
    } = this.props;
    window.actionName = isSentView
      ? "searcherPending"
      : isAcceptedView
      ? "searcherAccepted"
      : isInActiveView
      ? "searcherInActive"
      : isDeclinedView
      ? "searcherDeclined"
      : isIproActiveCollaboration
      ? "iproActive"
      : isIproInActiveCollaboration
      ? "iproInActive"
      : "iproNew";
    this.getCurrencies();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  getCurrencies(access_token) {
    this.props.onStateChangeAction({ isLoading: true });
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const currencies = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({
            currencies,
          });
          this.getAllCollaboration(currencies);
        } else {
          this.props.onStateChangeAction({ isLoading: false });
        }
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  }

  getAllCollaboration = (CurrenciesParams) => {
    const {
      url,
      isAcceptedView,
      isInActiveView,
      isDeclinedView,
      isSentView,
      isIproCollaboration,
      isIproNewCollaboration,
    } = this.props;
    let currencies = CurrenciesParams || this.props.currencies;
    this.props.onStateChangeAction({ fetchingCollaborations: true });
    getAllCollaborationApi({ url })
      .then((data) => {
        if (data.success) {
          let collaborations = [];
          if (data.items.length) {
            this.handleSelectCollaboration(data?.items?.[0]);
            collaborations = map(data.items, (collaboration) => {
              if (isSentView) {
                collaboration.IsRead = true;
              }
              if (isDeclinedView || isAcceptedView || isInActiveView) {
                collaboration.IsRead = collaboration.IsSearcherRead;
              }
              if (isIproCollaboration) {
                collaboration.IsRead = collaboration.IsIProRead;
              }
              if (!isNaN(collaboration.HourlyRateType)) {
                collaboration.HourlyRateType = currencies.find(
                  (a) => a.CurrencyId == collaboration.HourlyRateType
                ).Name;
              }
              return {
                ...collaboration,
                label: collaboration?.Title,
                value: collaboration?.CollaborationId,
              };
            });
          } else {
            collaborations = data.items;
          }
          this.props.onStateChangeAction({
            collaborationList: collaborations,
            // filteredCollaborations: collaborations,
            fetchingCollaborations: false,
          });
          const CollaborationId = this.props.locationProp.state
            ? this.props.locationProp.state.CollaborationId
            : null;
          const spliturl = this.props.locationProp.pathname.match(/\d+/g);
          var CollabIDFromURL = spliturl ? parseInt(spliturl?.[0]) : null;

          if (CollaborationId != null || CollabIDFromURL != null) {
            let filteredCollab = data.items.filter(
              (coll) =>
                coll.CollaborationId == CollaborationId ||
                coll.CollaborationId == CollabIDFromURL
            );
            if (filteredCollab && filteredCollab.length > 0) {
              this.handleSelectCollaboration(filteredCollab[0]);
            } else {
              const info = {
                message: "Collaboration has already been deleted by Searcher",
                status: "error",
              };
              this.props.notificationAction(info);
            }
          }
          this.filterList(this.props.searchKey);
          this.props.onStateChangeAction({ isLoading: false });
        } else {
          this.props.onStateChangeAction({ isLoading: false });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  filterList = (searchKey) => {
    const { collaborationList } = this.props;
    const filteredCollaborations = filter(
      collaborationList,
      (collaboration) => {
        if (includes(toLower(collaboration.Title), toLower(searchKey))) {
          return collaboration;
        }
      }
    );
    this.props.onStateChangeAction({ filteredCollaborations });
  };
  handleSearchChange = (e) => {
    const searchKey = e.target.value;
    // const filteredCollaborations = filter(collaborationList, collaboration => {
    //   if (includes(collaboration.Title, searchKey)) {
    //     return collaboration;
    //   }
    // });
    this.props.onStateChangeAction({ searchKey });
    this.filterList(searchKey);
  };

  handleSelectCollaboration = (selectedCollaboration) => {
    const { CollaborationId } = selectedCollaboration;
    const { isInActiveView, isIproInActiveCollaboration } = this.props;
    if (selectedCollaboration.IsRead) {
    } else {
      if (!selectedCollaboration.IsRead) {
        const {
          acceptedCollaboration,
          declinedCollaboration,
          isAcceptedView,
          isInActiveView,
          isDeclinedView,
          isIproCollaboration,
          unreadproInactiveCollaborations,
          searcherInActiveCollaboration,
          isIproInActiveCollaboration,
          unreadCollaborations,
        } = this.props;
        readCollaborationApi({
          collaborationId: CollaborationId,
        })
          .then((response) => {
            let { collaborationList } = this.props;

            const unreadCollabaorations = filter(
              collaborationList,
              (item) => item.CollaborationId !== CollaborationId && !item.IsRead
            );

            if (isIproCollaboration) {
              this.props.unreadCollaborationsAction({
                unreadCollaborations: unreadCollabaorations.length,
                unreadproInactiveCollaborations: unreadproInactiveCollaborations,
              });
            }
            if (isIproInActiveCollaboration) {
              this.props.unreadCollaborationsAction({
                unreadproInactiveCollaborations: unreadCollabaorations.length,
                unreadCollaborations: unreadCollaborations,
              });
            }
            if (isInActiveView) {
              this.props.unreadCollaborationsAction({
                searcherInActiveCollaboration: unreadCollabaorations.length,
                declinedCollaboration: declinedCollaboration,
                acceptedCollaboration: acceptedCollaboration,
              });
            }
            if (isAcceptedView) {
              this.props.unreadCollaborationsAction({
                acceptedCollaboration: unreadCollabaorations.length,
                declinedCollaboration: declinedCollaboration,
                searcherInActiveCollaboration: searcherInActiveCollaboration,
              });
            }
            if (isDeclinedView) {
              this.props.unreadCollaborationsAction({
                acceptedCollaboration: acceptedCollaboration,
                declinedCollaboration: unreadCollabaorations.length,
                searcherInActiveCollaboration: searcherInActiveCollaboration,
              });
            }

            const filteredCollaborations = map(collaborationList, (item) => {
              if (item.CollaborationId === CollaborationId) {
                item.IsRead = true;
              }
              return item;
            });
            this.props.onStateChangeAction({
              filteredCollaborations,
              selectedCollaboration,
            });
          })
          .catch((res) => res);
      }
    }

    const selectedUser = {
      ...selectedCollaboration.company,
      ...selectedCollaboration.userdetail,
    };
    if (isIproInActiveCollaboration || isInActiveView) {
      this.getReviewDetails(CollaborationId);
    }
    this.props.onStateChangeAction({
      selectedCollaboration,
      selectedUser,
      selectedCompany: selectedCollaboration.company,
      dialogMessage: "",
      yesClickCount: 0,
    });
  };

  handleCollaborationDelete = () => {
    const { collaborationDeleteConfirmation } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: collaborationDeleteConfirmation,
    });
    this.setState({ isDeleteCollaboration: true });
  };

  handleCollaborationClose = () => {
    const { collaborationCloseConfirmation } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: collaborationCloseConfirmation,
    });
    if (this.props.isSentView) {
      this.closeCollaboration();
    }
  };

  handleYesClick = () => {
    const {
      yesClickCount,
      likeToRateCollaborationMessage,
      selectedCollaboration,
    } = this.props;
    const { isDeleteCollaboration } = this.state;
    if (isDeleteCollaboration) {
      this.deleteCollaboration();
      return;
    }
    if (!yesClickCount || yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: likeToRateCollaborationMessage,
        yesClickCount: 1,
        fetchingReviews: true,
      });
      return;
    }
    this.props.onStateChangeAction({ fetchingReviews: true });
    this.getReviewDetails(selectedCollaboration.CollaborationId);
    this.getCollaborationCloseReasons();
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 2,
    });
    if (this?.state?.windowWidth < 768) {
      this.handleListOpenMobile();
    }
  };

  deleteCollaboration = () => {
    const {
      selectedCollaboration: { CollaborationId },
      IsSearcher,
      collaborationDeletedMsg,
    } = this.props;
    this.setState({ isLoading: true, dialogMessage: "" });
    const apiParams = `collaborationId=${CollaborationId}&${
      IsSearcher ? "IsSearcher=true" : "IsSearcher=false"
    }`;
    this.props.onStateChangeAction({ isLoading: true, dialogMessage: "" });
    deleteCollaborationApi({ apiParams }).then((response) => {
      if (response.success) {
        const info = {
          message: collaborationDeletedMsg,
          status: "success",
        };
        this.props.notificationAction(info);
        this.getAllCollaboration();
        this.props.onStateChangeAction({
          isLoading: false,
          selectedCollaboration: {},
          yesClickCount: 0,
        });
      } else {
        const info = {
          message: "Something went wrong",
          status: "error",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({
          isLoading: false,
        });
      }
    });
  };

  closeCollaboration = () => {
    const {
      selectedCollaboration: { CollaborationId },
      collaborationCloseMsg,
      IsSearcher,
    } = this.props;
    this.setState({ isLoading: true, dialogMessage: "" });
    const apiParams = `collaborationId=${CollaborationId}&IsClosed=true&${
      IsSearcher ? "IsSearcher=true" : "IsSearcher=false"
    }`;
    this.props.onStateChangeAction({ isLoading: true, dialogMessage: "" });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationCloseMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
        yesClickCount: 0,
      });
    });
  };

  handleNoClick = () => {
    const { yesClickCount } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: "",
        selectedCompany: null,
      });
      return;
    }
    this.closeCollaboration();
  };

  handleCollaborationAccept = () => {
    const {
      selectedCollaboration: { CollaborationId },
    } = this.props;
    const { collaborationAcceptedMsg } = this.props;
    const apiParams = `collaborationId=${CollaborationId}&IsDeclined=false`;
    this.props.onStateChangeAction({ isLoading: true });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationAcceptedMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
      });
    });
  };

  handleCollaborationDecline = () => {
    const {
      selectedCollaboration: { CollaborationId },
    } = this.props;
    const { collaborationDeclinedMsg } = this.props;
    const apiParams = `collaborationId=${CollaborationId}&IsDeclined=true`;
    this.props.onStateChangeAction({ isLoading: true });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationDeclinedMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
      });
    });
  };

  getReviewDetails = (CollaborationId) => {
    this.setState({ fetchingReviews: true });
    const { IsSearcher } = this.props;
    getReviewQuestions({ questionType: 1 })
      .then((res) => {
        const reviewQuestions = res.items.map((r) => ({
          ...r,
          ReviewQuestionValue: this.props.labels[r.Question],
          Answer: r.Question !== "Rating_Question5" ? r.Answer || 0 : "",
          CollaborationId: CollaborationId,
        }));
        getCollaborationReviewApi({
          collaborationId: CollaborationId,
          IsIProReview: !IsSearcher,
        })
          .then((reviewRes) => {
            const reviews = reviewRes.items;
            let currentCollborationCloseReasonId = null;
            this.props.onStateChangeAction({
              reviewQuestions: reviewQuestions.map((a) => {
                const currentReview = reviews
                  ? reviews.find(
                      (b) => b.ReviewQuestionId == a.ReviewQuestionId
                    )
                  : null;
                if (
                  currentReview &&
                  a.ReviewQuestionId == currentReview.ReviewQuestionId &&
                  a.Question === "Rating_Question6"
                ) {
                  currentCollborationCloseReasonId =
                    currentReview.CollborationCloseReasonId;
                }
                this.setState({ currentReview: currentReview });
                return {
                  ...a,
                  Answer:
                    currentReview != null ? currentReview.Answer : a.Answer,
                  IsIProReview: !IsSearcher,
                };
              }),
              isAlreadyRated: reviews && reviews.length > 0 ? true : false,
            });
            this.setState({ fetchingReviews: false });
            this.onFormSelectChange("", {
              value: currentCollborationCloseReasonId,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ fetchingReviews: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ fetchingReviews: false });
      });
  };

  getCollaborationCloseReasons = () => {
    getCollaborationReasonsApi()
      .then((reasons) => {
        const reasonsList = reasons.items.map((item) => ({
          ...item,
          value: item.CollborationCloseReasonId,
          label: this.props.labels[item.ReasonLabel],
        }));
        this.setState({
          options: reasonsList,
        });
      })
      .catch((err) => console.log(err));
  };

  handleRatingChange = (rating, value) => {
    const { reviewQuestions } = this.props;
    this.props.onStateChangeAction({
      reviewQuestions: reviewQuestions.map((a) =>
        a.ReviewQuestionId == rating.ReviewQuestionId
          ? {
              ...a,
              Answer:
                rating.Question === "Rating_Question5"
                  ? value.target.value
                  : value,
            }
          : a
      ),
    });
  };

  onFormSelectChange = (answer, selectedOption) => {
    if (!selectedOption) return;
    const { reviewQuestions } = this.props;
    const { value } = selectedOption;
    this.setState({
      CollborationCloseReasonId: value,
    });
    this.props.onStateChangeAction({
      reviewQuestions: reviewQuestions.map((a) =>
        a.Question === "Rating_Question6"
          ? {
              ...a,
              Answer: null,
              CollborationCloseReasonId: value,
            }
          : a
      ),
    });

    return;
  };

  handleSubmit = () => {
    const { reviewQuestions } = this.props;
    submitCollaborationReview({ collaborationReview: reviewQuestions })
      .then((data) => {
        this.setState((st) => ({ ...st, mobileModal: false }));
      })
      .catch((err) => {});
    this.closeCollaboration();
  };
  renderDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  componentWillUnmount = () => {};

  handleMobileModalClose = () => {
    this.setState((st) => ({ ...st, mobileModal: false }));
  };

  handleListOpenMobile = () => {
    this.setState((st) => ({ ...st, mobileModal: true }));
  };

  render() {
    const {
      searchInputPlaceholder,
      emptyCollaorationInfo,
      startDateLabel,
      durationLabel,
      compensationLabel,
      companyLabel,
      descriptionLabel,
      acceptedCollaboration,
      selectInterest,
      companyAddressLabel,
      comapnyIndustryLabel,
      companyCountryLabel,
      companyPhoneLabel,
      companyWebUrlLabel,
      companyVatLabel,
      IsSearcher,
      popupNoText,
      popupYesText,
      selectedCollaboration,
      filteredCollaborations,
      selectedUser,
      isLoading,
      selectedCompany,
      dialogMessage,
      yesClickCount = 0,
      isInActiveView,
      isIproInActiveCollaboration,
      isDeclinedView,
      isAcceptedView,
      isSentView,
      isIproActiveCollaboration,
      isIproNewCollaboration,
      iProOpportunityNewDetailBtnAccept,
      iProOpportunityNewDetailBtnDecline,
    } = this.props;
    const { fetchingReviews } = this.state;
    const isRatingView =
      ((isInActiveView || isIproInActiveCollaboration) &&
        !isNullOrUndefined(selectedCollaboration)) ||
      (yesClickCount &&
        yesClickCount === 2 &&
        !isNullOrUndefined(selectedCollaboration))
        ? true
        : false;


    return (
      <PageWrapper className="collaboration">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={this.handleYesClick}
              >
                {popupYesText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={this.handleNoClick}
              >
                {popupNoText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        <UserDetailModal
          isModalOpen={this?.state?.mobileModal}
          setIsModalOpen={this.handleMobileModalClose}
          selectedUser={selectedUser}
          labels={this?.props?.labels}
          selectedCollaboration={selectedCollaboration}
          onFormSelectChange={this.onFormSelectChange}
          options={this.state.options}
          onCloseCollaboration={this.closeCollaboration}
          CollborationCloseReasonId={this.state.CollborationCloseReasonId}
          reviewQuestions={this.props.reviewQuestions}
          onSubmit={this.handleSubmit}
          onRatingChange={this.handleRatingChange}
          isAlreadyRated={this.props.isAlreadyRated}
          isInActiveView={isInActiveView}
          isRatingView={isRatingView}
          IsSearcher={IsSearcher}
          selectedCompany={selectedCompany}
          addressLabel={companyAddressLabel}
          industryLabel={comapnyIndustryLabel}
          countryLabel={companyCountryLabel}
          phoneLabel={companyPhoneLabel}
          webUrlLabel={companyWebUrlLabel}
          vatLabel={companyVatLabel}
        />

        <Row className="new-search-resume-main">
          <Col
            xs={24}
            md={Object.keys(selectedCollaboration).length == 0 ? 24 : 10}
            className="h-100  "
          >
            <div className="h-100 flex flex-column">
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Col xs={22} md={24}>
                  <Select
                    style={{
                      width: "100%",
                      overflow: "auto",
                    }}
                    placeholder={searchInputPlaceholder}
                    size="large"
                    optionLabelProp="label"
                    value={selectedCollaboration?.CollaborationId}
                    onChange={(e) => {
                      const filterOption = filteredCollaborations?.find(
                        (row) => row?.value == e
                      );
                      this.handleSelectCollaboration(filterOption);
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {filteredCollaborations?.map((task,index) => {
                      return (
                        <Option
                          key={index}
                          value={task.value}
                          label={task.label}
                        >
                          <div className="align-items-center d-flex w-100 justify-content-between">
                            <span
                              style={{
                                paddingRight: "6px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                color: !task?.IsRead ? "#f6aa3a" : "",
                              }}
                            >
                              {task.label}
                            </span>
                            <span
                              style={{
                                color: !task?.IsRead ? "#f6aa3a" : "",
                              }}
                            >
                              {this.renderDate(task.StartDate)}
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col
                  md={0}
                  xs={2}
                  onClick={() => {
                    if (selectedCollaboration.CollaborationId) {
                      this.handleListOpenMobile();
                    }
                  }}
                >
                  <MenuDots style={{ float: "right" }} />
                </Col>
              </Row>
              <div
                style={{
                  marginTop: "15px",
                  overflow: "auto",
                  paddingRight: "6px",
                }}
              >
                <NewCollaborationDetail
                  selectedCollaboration={selectedCollaboration}
                  iProOpportunityNewDetailBtnDecline={
                    iProOpportunityNewDetailBtnDecline
                  }
                  iProOpportunityNewDetailBtnAccept={
                    iProOpportunityNewDetailBtnAccept
                  }
                  emptyCollaorationInfo={emptyCollaorationInfo}
                  startDateLabel={startDateLabel}
                  durationLabel={durationLabel}
                  isDeclinedView={isDeclinedView}
                  compensationLabel={compensationLabel}
                  companyLabel={companyLabel}
                  descriptionLabel={descriptionLabel}
                  acceptedCollaboration={acceptedCollaboration}
                  selectInterest={selectInterest}
                  onCollaborationAccept={this.handleCollaborationAccept}
                  onCollaborationDecline={this.handleCollaborationDecline}
                  onCollaborationDelete={this.handleCollaborationDelete}
                  onCollaborationClose={this.handleCollaborationClose}
                  IsSearcher={IsSearcher}
                  isIproInActiveCollaboration={isIproInActiveCollaboration}
                  isInActiveView={isInActiveView}
                  isAcceptedView={isAcceptedView}
                  isSentView={isSentView}
                  isIproActiveCollaboration={isIproActiveCollaboration}
                  isIproNewCollaboration={isIproNewCollaboration}
                />
              </div>
            </div>
          </Col>
          <Col
            md={14}
            xs={0}
            offset={0.5}
            className={
              Object.keys(selectedCollaboration).length == 0 ? null : "h-100"
            }
          >
            {selectedCollaboration.company && !isRatingView && (
              <div className="h-100">
                {IsSearcher ? (
                  <NewUserDetail
                    selectedUser={selectedUser}
                    labels={this.props.labels}
                  />
                ) : (
                  <CompanyDetailNew
                    selectedCompany={selectedCompany}
                    addressLabel={companyAddressLabel}
                    industryLabel={comapnyIndustryLabel}
                    countryLabel={companyCountryLabel}
                    phoneLabel={companyPhoneLabel}
                    webUrlLabel={companyWebUrlLabel}
                    vatLabel={companyVatLabel}
                  />
                )}
              </div>
            )}
            {isRatingView && (
              <div className="h-100">
                {fetchingReviews ? (
                  <LoadingMaskRow />
                ) : (
                  <>
                    {!isEmpty(selectedCollaboration) && (
                      <NewRatingView
                        selectedCollaboration={selectedCollaboration}
                        onFormSelectChange={this.onFormSelectChange}
                        options={this.state.options}
                        onCloseCollaboration={this.closeCollaboration}
                        CollborationCloseReasonId={
                          this.state.CollborationCloseReasonId
                        }
                        reviewQuestions={this.props.reviewQuestions}
                        onSubmit={this.handleSubmit}
                        onRatingChange={this.handleRatingChange}
                        isAlreadyRated={this.props.isAlreadyRated}
                        isInActiveView={isInActiveView}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (
  {
    systemLabel,
    navigation,
    iproCollaborationNew,
    iproCollaborationInActive,
    iproCollaborationActive,
    searcherCollaborationPending,
    searcherCollaborationAccepted,
    searcherCollaborationDeclined,
    searcherCollaborationInActive,
  },
  {
    isIproActiveCollaboration,
    isIproInActiveCollaboration,
    isSentView,
    isAcceptedView,
    isDeclinedView,
    isInActiveView,
  }
) => {
  const {
    acceptedCollaboration,
    declinedCollaboration,
    unreadproInactiveCollaborations,
    searcherInActiveCollaboration,
    unreadCollaborations,
  } = navigation;
  const { labels } = systemLabel;
  const collaborationProps = isSentView
    ? searcherCollaborationPending
    : isAcceptedView
    ? searcherCollaborationAccepted
    : isDeclinedView
    ? searcherCollaborationDeclined
    : isInActiveView
    ? searcherCollaborationInActive
    : isIproActiveCollaboration
    ? iproCollaborationActive
    : isIproInActiveCollaboration
    ? iproCollaborationInActive
    : iproCollaborationNew;
  return {
    labels,
    acceptedCollaboration,
    declinedCollaboration,
    searcherInActiveCollaboration,
    unreadproInactiveCollaborations,
    unreadCollaborations,
    ...collaborationProps,
  };
};

const actions = {
  unreadCollaborationsAction,
  onStateChangeAction,
};
export default connect(mapStateToProps, actions)(NewCollaboration);
