import React from "react";
import { Modal } from "antd";
import MessageDetail from "./MessageDetail";

const MobileSentDetailModal = (props) => {
  const { selectedMessage, labels, mobileModal, handleClose } = props;

  const handleOk = () => {
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        title=""
        open={mobileModal}
        style={{ top: 20 }}
        onOk={handleOk}
        zIndex={100}
        onCancel={handleCancel}
        footer={false}
        className="new-search-resume-main for-mobile-modal-shortlist"
      >
        <div style={{ marginTop: "5px" }} className="new-search-resume-main">
          <MessageDetail selectedMessage={selectedMessage} labels={labels} />
        </div>
      </Modal>
    </>
  );
};
export default MobileSentDetailModal;
