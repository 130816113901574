import React, { Component } from "react";
import { EmptyInfo, Button } from "../../../../../common";
import moment from "moment";
import "./newCollaborationDetail.scss";
import { Col, Row } from "antd";

class NewCollaborationDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  render() {
    const {
      selectedCollaboration,
      emptyCollaorationInfo,
      startDateLabel,
      durationLabel,
      compensationLabel,
      companyLabel,
      descriptionLabel,
      isIproActiveCollaboration,
      selectInterest,
      onCollaborationAccept,
      onCollaborationDecline,
      onCollaborationDelete,
      onCollaborationClose,
      IsSearcher,
      isInActiveView,
      isIproNewCollaboration,
      isSentView,
      isIproInActiveCollaboration,
      isAcceptedView,
      iProOpportunityNewDetailBtnAccept,
      iProOpportunityNewDetailBtnDecline,
    } = this.props;
    return (
      <div className="new-collaboration-detail-component">
        {!selectedCollaboration.CollaborationId ? (
          <EmptyInfo>{emptyCollaorationInfo}</EmptyInfo>
        ) : (
          <div className="inner-user">
            <p className="user-title">{selectedCollaboration.Title}</p>
            {selectedCollaboration.StartDate && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="user-detail">{startDateLabel}:</p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {this.renderDate(selectedCollaboration.StartDate)}
                  </p>
                </Col>
              </Row>
            )}
            {selectedCollaboration.Duration && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="user-detail"> {durationLabel}:</p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {" "}
                    {selectedCollaboration.Duration}
                  </p>
                </Col>
              </Row>
            )}
            {selectedCollaboration.HourlyRate && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="user-detail">{compensationLabel}:</p>
                </Col>

                <Col flex="auto">
                  <p className="user-detail">
                    {selectedCollaboration.HourlyRate} &nbsp;
                    {selectedCollaboration.HourlyRateType}{" "}
                  </p>
                </Col>
              </Row>
            )}

            {selectedCollaboration.company.CompanyName && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="user-detail">{companyLabel}:</p>
                </Col>

                <Col flex="auto">
                  <p className="user-detail">
                    {selectedCollaboration.company.CompanyName}
                  </p>
                </Col>
              </Row>
            )}
            {selectedCollaboration.Decription && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="user-detail">{descriptionLabel}:</p>
                </Col>

                <Col flex="auto">
                  <p className="user-detail">
                    {selectedCollaboration.Decription}
                  </p>
                </Col>
              </Row>
            )}
            <div className="">
              {((IsSearcher && isAcceptedView) ||
                (!IsSearcher && isIproActiveCollaboration)) && (
                <div className="btn-footer">
                  <button className="btn-delete" onClick={onCollaborationClose}>
                    Close
                  </button>
                </div>
              )}
              {IsSearcher && isSentView && (
                <div className="btn-footer">
                  <button
                    className="btn-delete"
                    onClick={onCollaborationDelete}
                  >
                    Delete
                  </button>
                </div>
              )}

              {!IsSearcher && isIproNewCollaboration && (
                <div>
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="auto">
                      <p className="user-detail" style={{ fontWeight: 600 }}>
                        {selectInterest}:
                      </p>
                    </Col>
                  </Row>

                  <div className="btn-footer-ipro">
                    <button
                      className="btn-delete clr-purple "
                      onClick={() => onCollaborationAccept()}
                    >
                      {iProOpportunityNewDetailBtnAccept}
                    </button>
                    <button
                      className="btn-delete clr-red ml-2"
                      onClick={() => onCollaborationDecline()}
                    >
                      {iProOpportunityNewDetailBtnDecline}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NewCollaborationDetail;
