import React, { Component } from "react";
import { PageWrapper } from "../../../components";
import { connect } from "react-redux";
import { LoadingMaskRow, ConfirmDialog, EmptyInfo } from "../../../common";
import { notificationAction } from "../../../actions";
import "./messagesent.scss";
import { getSentMessagesApi, updateMessageApi } from "../messageApi";
import MessageDetail from "./components/MessageDetail";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import { onStateChangeAction } from "./messageSentAction";
import { Col, Row, Input, Space } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import MobileSentDetailModal from "./components/mobileModal";

class MessageSent extends Component {
  state = {
    mobileModal: false,
  };

  componentDidMount() {
    this.getAllMessages();
  }

  getAllMessages = () => {
    this.props.onStateChangeAction({ isFetching: true });
    getSentMessagesApi().then((data) => {
      if (data.success) {
        const messageList = data.items.map((item) => ({
          ...item,
          UserRequestMessage: first(item.UserRequestMessage),
        }));
        this.props.onStateChangeAction({
          messageList,
          filterMessages: messageList,
        });
      }
      this.props.onStateChangeAction({ isFetching: false });
    });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { messageList } = this.props;
    const filterMessages = filter(messageList, (message) =>
      includes(toLower(message.Subject), toLower(value))
    );
    this.props.onStateChangeAction({ filterMessages, searchKey: value });
  };

  handleContentChange = (e) => {
    const { value, name } = e.target;
    const { messageForm } = this.props;
    const data = {
      ...messageForm,
      [name]: value,
    };
    this.props.onStateChangeAction({ messageForm: data });
  };

  handleDelete = (message) => {
    const { labels } = this.props;
    this.props.onStateChangeAction({
      selectedMessage: message,
      dialogMessage: labels.InfoIProSentMsgDeleteConfirmation,
    });
  };

  handleYesClick = () => {
    const { notificationAction, labels } = this.props;

    const message = {
      MessageId: this.props.selectedMessage.MessageId,
      IsDeleteSentMessage: true,
    };
    this.props.onStateChangeAction({
      isFetching: true,
      selectedMessage: {},
      dialogMessage: "",
    });
    updateMessageApi({ message }).then(() => {
      notificationAction({
        status: "success",
        message: labels.InfoIProSentMsgDeleteSuccessfully,
      });
      this.getAllMessages();
    });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({
      dialogMessage: "",
      selectedCompany: null,
    });
  };

  renderDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };
  handleClose = () => {
    this.setState((st) => ({ ...st, mobileModal: false }));
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const {
      selectedMessage,
      filterMessages,
      isFetching,
      dialogMessage,
      searchKey,
    } = this.props;
    return (
      <PageWrapper className="message-inbox">
        {dialogMessage && (
          <ConfirmDialog testId="message-sent-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="message-sent-delete-yes"
                onClick={this.handleYesClick}
              >
                {labels.companyDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="message-sent-delete-no"
                onClick={this.handleNoClick}
              >
                {labels.companyDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <MobileSentDetailModal
          selectedMessage={selectedMessage}
          labels={labels}
          mobileModal={this?.state?.mobileModal}
          handleClose={this.handleClose}
        />
        <Row className="new-search-resume-main">
          <Col xs={24} md={10} className="shortlist-lsit-section">
            <div className="h-100">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Input
                    size="large"
                    placeholder={
                      labels?.searcher_send_message_search_placeholder
                    }
                    onChange={this.handleSearchChange}
                    value={searchKey}
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    paddingRight: "6px",
                    overflow: "auto",
                  }}
                >
                  {isFetching ? (
                    <LoadingMaskRow />
                  ) : (
                    <>
                      {filterMessages?.length > 0 ? (
                        <Space
                          size={[6, 6]}
                          wrap
                          className="short-list-new company-list"
                        >
                          {filterMessages?.map((item) => (
                            <div
                              onClick={() => {
                                const mediaQuery = window.matchMedia(
                                  "(max-width: 768px)"
                                );
                                if (mediaQuery?.matches == true) {
                                  this.setState((st) => ({
                                    ...st,
                                    mobileModal: true,
                                  }));
                                }
                                this.props.onStateChangeAction({
                                  selectedMessage: item,
                                });
                              }}
                              key={item.MessageId}
                              className={`${
                                item.MessageId === selectedMessage.MessageId
                                  ? "background-shortlist short-list-item"
                                  : "short-list-item"
                              }`}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <p
                                className="text-left"
                                style={{ margin: 0, fontWeight: 600 }}
                              >
                                {item?.Subject}
                              </p>
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "18px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDelete(item);
                                }}
                              />
                            </div>
                          ))}
                        </Space>
                      ) : (
                        <EmptyInfo>
                          {labels.EMBARK_NO_ITEM_TO_DISPLAY}
                        </EmptyInfo>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>

          <Col md={14} xs={0} offset={0.5} className="h-100">
            <div className="column-secend">
              {isEmpty(selectedMessage) ? (
                <EmptyInfo>{labels.Message_Sent}</EmptyInfo>
              ) : (
                <MessageDetail
                  selectedMessage={selectedMessage}
                  labels={labels}
                />
              )}
            </div>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  messageSent,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  const { UserId } = user;
  const userId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  return { labels, user, UserId: userId, isHelpActive, ...messageSent };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(MessageSent);
