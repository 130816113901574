/* global window, document, localStorage */
import Keys from "./Keys";
import ReactHtmlParser from "react-html-parser";
import * as loadImage from "blueimp-load-image";

const applyFn = (state, fn) => fn(state);

export const pipe = (fns, state) =>
  state.withMutations(s => fns.reduce(applyFn, s));

export const isValidEmail = email =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const isValidPassword = password =>
  /^(?=.*[A-Za-z])(?=.*[0-9]).{8,}$/.test(
    password
  );

export const isValidURL = str => {
  const re = /(((^https?)|(^ftp)):\/\/([\-\w]+\.)+\w{2,3}(\/[%\-\w]+(\.\w{2,})?)*(([\w\-\.\?\\\/+@&#;`~=%!]*)(\.\w{2,})?)*\/?)/i;
  return re.test(str);
};

export const isValidPhoneNumber = number => {
  const regex = /^[\d\s\+?\(?\)?\-?\.?]+$/;
  return regex.test(number);
};

export const Common = {
  convertToInt(val) {
    return parseInt(val, 10);
  },
  // prepareFootageUrl(videoId, userId) {
  //   const { S3Base_URL } = config;
  //   return `${S3Base_URL + userId}/${videoId}.mp4`;
  // },
  // prepareShareFootageUrl(videoId, userId) {
  //   const { REST_API } = config;
  //   const hostLocation = window.location;
  //   return `${hostLocation.protocol +
  //     hostLocation.host +
  //     REST_API.Footage.ShareUrl +
  //     userId}/${videoId}`;
  // },
  clearAuthData(refreshPage) {
    localStorage.setItem(Keys.localStorage.isLoggedIn, false);
    localStorage.removeItem(Keys.localStorage.userId);
    localStorage.removeItem(Keys.localStorage.bearerToken);
    localStorage.removeItem(Keys.localStorage.fullName);
    if (refreshPage) {
      window.location.href = "";
    }
  },
  loaderDisplay(show) {
    const element = document.getElementsByClassName("loaderBg")[0];
    if (show) {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  }
};

export const htmlParser = item => {
  return ReactHtmlParser(item);
};

export const trimSpaces = text => text && text.replace(/\s+/g, " ").trim();

export const dateFormate = date => {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var time = date.toLocaleTimeString();

  return day + " " + monthNames[monthIndex] + " " + year + " at " + time;
};

export const loadImageOrientation = (acceptedFile, callback) => {
  const loadImageOptions = {
    maxWidth: 1000,
    maxHeight: 1000,
    canvas: true
  };
  loadImage.parseMetaData(acceptedFile, data => {
    if (data.exif && data.exif.get("Orientation")) {
      loadImageOptions.orientation = data.exif.get("Orientation");
    }
    loadImage(
      acceptedFile,
      canvas => {
        const base64Image = canvas.toDataURL(acceptedFile.type);
        callback(base64Image);
      },
      loadImageOptions
    );
  });
};

export const roles = {
  ipro: "ipro",
  searcher: "searcher"
};
