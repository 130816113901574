import React, { Component } from "react";
import { PageWrapper } from "../../../components";
import { connect } from "react-redux";
import { EmptyInfo } from "../../../common";
import { notificationAction } from "../../../actions";
import "./messagenew.scss";
import { getAllUserApi, sendMessage } from "../messageApi";
import CreateMessage from "./components/CreateMessage";
import EmptyCreateMessage from "./EmptyCreateMessage";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";
import includes from "lodash/includes";
import { onStateChangeAction } from "./messageNewAction";
import toLower from "lodash/toLower";
import { Col, Row, Input, Space, Radio, Select } from "antd";

class MessageNew extends Component {
  state = { searchListHover: false };

  componentDidMount() {
    var url = this.props.location.pathname;
    const lastSegment = url.split("/").pop();
    this.setState({
      assortAction: lastSegment,
    });
    this.getAllUsers();
  }

  getAllUsers = () => {
    const { IsFreelancer, notificationAction, labels } = this.props;
    this.props.onStateChangeAction({ isFetching: true });
    getAllUserApi({ IsFreelancer }).then((data) => {
      if (data.success) {
        if (isEmpty(data.items)) {
          notificationAction({
            status: "info",
            message: IsFreelancer
              ? labels.InfoIProCreateMsgRestriction
              : labels.searcherCreateMessageWithoutInvitationInfo,
          });
        }
        const filterData = data?.items?.map((single) => {
          return {
            ...single,
            label: single?.UserFirstname,
            value: single?.UserId,
          };
        });
        this.props.onStateChangeAction({
          users: filterData,
          filterUsers: filterData,
          isFetching: false,
        });
      } else {
        this.props.onStateChangeAction({ isFetching: false });
      }
    });
  };

  handleContentChange = (e) => {
    const { value, name } = e.target;
    const { messageForm } = this.props;
    const data = {
      ...messageForm,
      [name]: value,
    };
    this.props.onStateChangeAction({ messageForm: data });
  };

  handleSubmitMessage = () => {
    const {
      messageForm: { title, content },
      selectedUsers,
    } = this.props;
    const { notificationAction, labels } = this.props;
    if (isEmpty(title)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgTitleError,
      });
      return;
    }
    if (isEmpty(content)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgBodyError,
      });
      return;
    }
    if (isEmpty(selectedUsers)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgEmptyReceiverError,
      });
      return;
    }
    const message = {
      IsRead: false,
      MessageBody: content,
      MessageId: 0,
      Subject: title,
      UserIds: map(selectedUsers, (user) => user.UserId),
      UserRequestMessageId: 0,
    };

    this.props.onStateChangeAction({ isFetching: true });
    sendMessage({ message })
      .then((response) => {
        this.props.onStateChangeAction({
          selectedUsers: [],
          messageForm: { title: "", content: "" },
          isFetching: false,
        });
        notificationAction({
          status: "success",
          message: labels.InfoIProCreateMsgAddSuccessfully,
        });
      })
      .catch((error) => {
        this.props.onStateChangeAction({ isFetching: false });
      });
  };

  handleSearchChange = (e) => {
    const searchKey = e.target.value;
    const { users } = this.props;
    const filterUsers = filter(users, (user) => {
      if (includes(toLower(user.UserFirstname), toLower(searchKey))) {
        return user;
      }
    });
    this.props.onStateChangeAction({ filterUsers });
  };

  handleSearchListSelected = ({ selectedUser }) => {
    const { notificationAction, labels, selectedUsers } = this.props;
    const alreadySelected = filter(
      selectedUsers,
      (user) => user.UserId === selectedUser.UserId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedUsers: [...selectedUsers, selectedUser],
        isSearchFocus: false,
      });
      return;
    } else {
      const duplicate = selectedUsers?.filter(
        (x) => x?.UserId != selectedUser?.UserId
      );
      this.props.onStateChangeAction({
        selectedUsers: duplicate,
        isSearchFocus: false,
      });
    }
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.props.onStateChangeAction({ isSearchFocus: false });
  };

  handleRemoveSelectedUser = (item) => {
    let { selectedUsers } = this.props;
    selectedUsers = filter(
      selectedUsers,
      (user) => user.UserId !== item.UserId
    );
    this.props.onStateChangeAction({ selectedUsers });
  };

  render() {
    const {
      listCollapsed,
      formCollapsed,
      users,
      messageForm,
      isSearchFocus,
      selectedUsers,
      isFetching,
      filterUsers,
      labels,
      notificationAction,
      isHelpActive,
      createMessageCollapsed,
    } = this.props;

    return isEmpty(users) ? (
      <EmptyCreateMessage
        testId="not-able-to-send-message"
        labels={labels}
        onStateChangeAction={this.props.onStateChangeAction}
        createMessageCollapsed={createMessageCollapsed}
        notificationAction={notificationAction}
        isHelpActive={isHelpActive}
        IsFreelancer={this.props.IsFreelancer}
      />
    ) : (
      <PageWrapper className="message-new">
        <Row className="new-search-resume-main">
          <Col xs={0} md={10} className="opportunity-create-form-new">
            <div className="h-100">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Input
                    size="large"
                    placeholder={
                      labels?.searcher_create_message_textfield_placeholder
                    }
                    onChange={this.handleSearchChange}
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    overflow: "auto",
                  }}
                >
                  {filterUsers?.length > 0 ? (
                    <Space
                      size={[6, 6]}
                      wrap
                      className="short-list-new company-list p"
                    >
                      {filterUsers.map((item) => {
                        const selected = selectedUsers?.some(
                          (x) => x?.UserId == item?.UserId
                        );
                        return (
                          <div
                            onClick={() =>
                              this.handleSearchListSelected({
                                selectedUser: item,
                              })
                            }
                            key={item.UserId}
                            className={`${
                              selected
                                ? "background-shortlist short-list-item pointer"
                                : "short-list-item pointer"
                            }`}
                          >
                            <div className=" d-flex align-items-center">
                              <Radio
                                value={item?.ResumeId}
                                checked={selected}
                                style={{
                                  fontSize: "large",
                                }}
                              />
                              <div className={"text-left"} style={{fontWeight:'600'}}>
                                {item.UserFirstname}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Space>
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={0} className="message-search">
            <Select
              style={{
                width: "100%",
              }}
              placeholder={
                labels?.searcher_create_message_textfield_placeholder
              }
              size="large"
              onChange={(e, option) => {
                this.props.onStateChangeAction({
                  selectedUsers: option,
                  isSearchFocus: false,
                });
              }}
              showSearch
              mode="multiple"
              tagRender={(e) => {
                return ` ${e?.label}, `;
              }}
              value={selectedUsers || []}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.props?.label
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
              options={filterUsers || []}
            />
          </Col>
          <Col md={14} xs={24} offset={0.5} className="column-secend">
            <div className="h-100">
              <CreateMessage
                handleContentChange={this.handleContentChange}
                messageForm={messageForm}
                isFetching={isFetching}
                onMessageSend={this.handleSubmitMessage}
              />
            </div>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation, messageNew }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return { labels, IsFreelancer, isHelpActive, ...messageNew };
};
export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(MessageNew);
