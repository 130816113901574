import React, { Component } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import "./messagedetail.scss";
import { Messages } from "../../../../utilities";
import { Col, Row } from "antd";

class MessageDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("M/D/YY");
  };

  renderTime = (date) => {
    return moment
      .utc(date)
      .local()
      .format("hh:mm a");
  };

  render() {
    const { selectedMessage, labels } = this.props;

    return (
      <div className="new-user-detail-component">
        <div className="inner-user">
          <p className="user-title">{selectedMessage.Subject}</p>
          <Row
            gutter={[8, 12]}
            style={{ marginTop: "10px", flexFlow: "unset" }}
          >
            <Col flex="100px">
              <p className="user-detail">
                {labels.IProSentMessageDetailToLabel}:
              </p>
            </Col>
            <Col flex="auto">
              <p className="user-detail">
                {ReactHtmlParser(selectedMessage.RecieverName)}
              </p>
            </Col>
          </Row>
          <Row
            gutter={[8, 12]}
            style={{ marginTop: "10px", flexFlow: "unset" }}
          >
            <Col flex="100px">
              <p className="user-detail">
                {labels.IProSentMessageDetailDateLabel}:
              </p>
            </Col>
            <Col flex="auto">
              <p className="user-detail">
                {this.renderDate(selectedMessage.DateCreated)}
              </p>
            </Col>
          </Row>
          <Row
            gutter={[8, 12]}
            style={{ marginTop: "10px", flexFlow: "unset" }}
          >
            <Col flex="100px">
              <p className="user-detail">
                {labels.IProSentMessageDetailTimeLabel}:{" "}
              </p>
            </Col>
            <Col flex="auto">
              <p className="user-detail">
                {this.renderTime(selectedMessage?.DateCreated)}
              </p>
            </Col>
          </Row>
          <Row
            gutter={[8, 12]}
            style={{ marginTop: "10px", flexFlow: "unset" }}
          >
            <Col flex="auto">
              <p className="user-detail" style={{ whiteSpace: "pre" }}>
                {ReactHtmlParser(selectedMessage?.MessageBody)}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default MessageDetail;
