import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Select } from "antd";
import classes from "./index.module.scss";
import { getSkillsApi } from "../../dashboardApi";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import DebounceSelect from "../../../../components/DebounceSelect/DebounceSelect";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import LanguageImage from '../../../../assets/images/keywork-popup.png'

function EditSkillPopup({
  isLoading,
  SaveResumeDetails,
  errorMessage,
  labels,
  handleSkillCHange,
  resumeSkillCurrent,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => { }, []);

  const onFinish = () => {
    setIsModalVisible(false);
    SaveResumeDetails();
  };
  let SkillProficiencyList = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Proficient" },
    { value: 3, label: "Expert" },
  ];
  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.ResumeHeadingSkill}</button>

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className={`new-design-modal ${classes.popUpForm}`}
      >
        <div className="text-center mb-3 header-modal">
          <h5>{labels.ResumeHeadingSkill}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select skill related to your resume</p>
        </div>
        <Form
          name="normal_login"
          className={`${classes.form} form`}
          onFinish={onFinish}
          size="large"
        // initialValues={{ date: SkillProficiencyList[0] }}
        >
          <Form.Item
            rules={[
              {
                required: resumeSkillCurrent.SkillId ? false : true,
                message: labels.SELECT_SKILL_LBL || "Please select Skill",
              },
            ]}
            className={`${classes.customSelect}`}
            name="certification"
          >
            <DebounceSelect
              mode="multiple"
              selectName={"SkillId"}
              value={""}
              placeholder={labels.SELECT_SKILL_LBL}
              fetchOptions={getSkillsApi}
              handleChange={handleSkillCHange}
            />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: resumeSkillCurrent.ExperienceLevel ? false : true,
                message:
                  labels.SELECT_EXPERIECE_LEVEL_VALIDATION_LBL ||
                  "Please select experience level",
              },
            ]}
            className={`${classes.customSelect}`}
            name="ExperienceLevel"
          >
            <Select
              placeholder={labels.IPRO_DASHBOARD_RESUME_LANGUAGE_LEVEL_LABEL}
              onChange={(e) => handleSkillCHange("ExperienceLevel", e)}
              allowClear
              name={"ExperienceLevel"}
              autoComplete="new-password"
            >
              {SkillProficiencyList &&
                SkillProficiencyList.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            {labels.Done_LBL}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default EditSkillPopup;
