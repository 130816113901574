import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { htmlParser } from "../../utilities/helpers";
import { logoutAction } from "../../store/initialConfig";
import { helpActiveAction, resumeChangeAction } from "../../actions";
import { StorageService } from "../../api";
import { privateRoutes, publicRoutes } from "../../Routes/routing";
import LogoSrc from "../../assets/images/logo.png";
import { logoutApi } from "../Navigation/navigationApi";
import "./header.scss";
import { RESET_LANDINGPAGE_URL } from "../../utilities/enviroments";
import { Button } from "../../common";
import axios from "axios";
class Header extends Component {
  handleLogout = () => {
    const { isResumeChange, resumeChangeAction, labels } = this.props;
    if (isResumeChange) {
      resumeChangeAction({
        isResumeChange,
        message: labels.RESUME_EDIT_UNSAVED_CHANGES_MESSAGE,
        isLogoutTrigger: true,
      });
      return;
    }
    this.logout();
  };

  logout = () => {
    const { history } = this.props;
    let windowLocation = window.location.href;
    logoutApi().then((response) => {
      if (response.success) {
        delete axios.defaults.headers.common[".ASPXAUTH"];
        this.props.logoutAction();
        let windowLocation = window.location.href;
        StorageService.clearAll();
        if (
          windowLocation.indexOf("/apps/") >= 0 ||
          windowLocation.indexOf("app-login") >= 0 ||
          windowLocation.indexOf("/Apps/") >= 0
        ) {
          window.location.href = windowLocation;
        } else {
          window.location.href = RESET_LANDINGPAGE_URL;
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isLogout !== this.props.isLogout) {
      this.logout();
    }
  }

  handleHelpClick = () => {
    const { helpActiveAction, isHelpActive } = this.props;
    helpActiveAction({ isHelpActive: !isHelpActive });
  };

  render() {
    const {
      labels,
      user,
      onNavigationToggle,
      isHelpActive,
      history,
      hideUserSetting,
    } = this.props;
    const { FirstName, IsFreelancer, UserFirstname } = user;
     const isVisitor = IsFreelancer === null;
    return (
      <header className="page-header" test-data-id="application-header">
       {!hideUserSetting&& <button className="ToggleMenu" onClick={onNavigationToggle} />}
        <span className="user-name">
          {IsFreelancer
            ? htmlParser(labels.IPRO_APP_HEADER_GREETING_LABEL)
            : htmlParser(labels.SEARCHER_APP_HEADER_GREETING_LABEL)}{" "}
          {isVisitor ? "Visitor" : FirstName?.split(' ')[0] || UserFirstname?.split(' ')[0]}
        </span>
        <span
          className={`helpBtn ${isHelpActive ? "helpActive" : ""}`}
          onClick={this.handleHelpClick}
          test-data-id="header-help-btn"
        />

        <div className="header-logo-container">
          <img className="header-logo" src={LogoSrc} alt="logo" />
        </div>
        <Button
          followCursor={true}
          positionHelp="bottom"
          className="logout-button"
          mainClassName={"header-button"}
          onClick={this.handleLogout}
          alt="logout"
          tooltipButton={labels.HEADER_LOGOUT_ICON_TOOLTIP}
          tooltipHelp={labels.HEADER_LOGOUT_ICON_HELP}
          tooltipPlace="left"
          isHelpActive={isHelpActive}
          testId="logout-btn"
          testIdHelp="logout-help-btn"
          disabled={isVisitor}
        />
        {!hideUserSetting ? (
          <Button
            followCursor={true}
            positionHelp="bottom"
            className="setting-button"
            mainClassName={"header-button"}
            onClick={() => history.push(privateRoutes.settings.path)}
            alt="Settings"
            tooltipButton={labels.HEADER_SETTING_ICON_TOOLTIP}
            tooltipHelp={labels.HEADER_SETTING_ICON_HELP}
            tooltipPlace="left"
            isHelpActive={isHelpActive}
            testId="user-setting-settings-btn"
            testIdHelp="user-setting-settings-help-btn"
            disabled={isVisitor}
          />
        ) : (
          ""
        )}
        <Button
          followCursor={true}
          positionHelp="bottom"
          className="blog-button"
          mainClassName={"header-button"}
          onClick={() => window.open("https://prodoo.com/blog/", "_blank")}
          alt="blog"
          tooltipButton={"Blog"}
          tooltipHelp={"Blog"}
          tooltipPlace="left"
          isHelpActive={isHelpActive}
          testId="blog-btn"
          testIdHelp="blog-help-btn"
        />
      </header>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  resume: { isResumeChange, isLogout },
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  return {
    labels,
    user,
    isHelpActive,
    isResumeChange,
    isLogout,
  };
};

const actions = {
  logoutAction,
  helpActiveAction,
  resumeChangeAction,
};
export default connect(mapStateToProps, actions)(withRouter(Header));
