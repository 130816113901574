import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, includes, toLower, head } from "lodash";
import { PageWrapper } from "../../../components";
import { ConfirmDialog, EmptyInfo, LoadingMask } from "../../../common";
import { notificationAction } from "../../../actions";
import "./searcherOpportunitySent.scss";
import {
  getShortlistResumesApi,
  getSentOpportunityDetailApi,
  deleteSendOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getOpportunityResumesApi,
  getSentOpportunitesApi,
} from "../opportunityApi";
import {
  deleteResumeApi,
  getShortlistResumeApi,
  GetShortlistResumeCount,
} from "../../Shortlist/shortlistApi";
import {
  getOpportunityIprosResumeApi,
  getCurrenciesApi,
} from "../../Collaboration/common/collaborationApi";
import { onStateChangeAction } from "./searcherOpportunitySentAction";
import { Col, Row, Select } from "antd";
import ShortListResume from "../Searcher/NewCreateOpportunity/component/resumeResumeList";
import DetailModal from "../../newShortlist/component/detailModal";
import { CloseCircleOutlined } from "@ant-design/icons";
import MobileFilter from "./NewCreateOpportunity/component/mobileFilter.js";
import NewOpportunityDetail from "./common/NewOpportunityDetail";

const { Option } = Select;

class SearcherOpportunitySent extends Component {
  state = {
    declinedRequests: "",
    acceptedRequests: "",
    pendingRequests: "",
    isSearchFocus: false,
    searchListHover: false,
    showModal: { show: false },
    mobileModal: false,
  };

  getOpportunities = () => {
    const { isFreelancer } = this.props;
    this.props.onStateChangeAction({ isFetching: true, isLoading: true });
    getSentOpportunitesApi({ isFreelancer })
      .then((response) => {
        const {
          success,
          items: { Sent },
        } = response;
        if (success) {
          const filterData = response?.items?.Sent?.map((single) => {
            return {
              ...single,
              label: single?.RequestName,
              value: single?.RequestId,
            };
          });
          this.props.onStateChangeAction({
            opportunities: filterData,
            filterOpportunires: filterData,
            isFetching: false,
            isLoading: false,
          });
          const spliturl = this.props.location.pathname.match(/\d+/g);
          if (Sent.length > 0) {
            if (spliturl != null) {
              let filteredOppertunity = Sent.filter(
                (res) => res.RequestId == spliturl[0]
              );
              this.handleOpportunityClick({
                selectedOpportunity: filteredOppertunity[0],
              });
            } else {
              this.handleOpportunityClick({ selectedOpportunity: Sent[0] });
            }
          }
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  componentDidMount() {
    this.props.onStateChangeAction({
      opportunities: [],
      filterOpportunires: [],
      selectedOpportunity: {},
      shortlistResumes:[]
    });
    this.getOpportunities();
    this.getCurrencies();
  }

  replaceObjectKeys = ({ oldObject, mappingObject }) => {
    return Object.keys(oldObject).reduce((acc, key) => {
      acc[mappingObject[key]] = oldObject[key];
      return acc;
    }, {});
  };
  getOpportunityIProsResume = (requestId) => {
    getOpportunityIprosResumeApi(requestId).then((data) => {
      if (data.success) {
        const iprosList = map(data.items.user, (item) => {
          const newItem = {
            ...item,
            value: item.ResumeId,
            label: item.UserName,
          };

          return newItem;
        });
        this.props.onStateChangeAction({
          iprosList,
        });
      }
    });
  };
  handleOpportunityClick = ({ selectedOpportunity }) => {
    this.props.onStateChangeAction({ isLoading: true });
    const { RequestId } = selectedOpportunity;
    this.getOpportunityIProsResume(RequestId);
  
    this.props.onStateChangeAction({
      currentOpportunityId: RequestId,
      isFetchingShortlist: true,
      isFetchingShortlistResume: true,
      selectedShortlists: [],
      selectedOpportunity,
      shortlistResumes: [],
      selectedResume: {},
      selectedIpro: {},
      hideShortList: false,
    });
  
    getSentOpportunityDetailApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
  
          const filterShortList = items?.ShortLists?.map((single) => ({
            ...single,
            label: single?.ShortlistName,
            value: single?.ShortlistId,
          }));
  
          const selectedOpportunity = {
            ...items,
            FeeCurrencyType: this.props.allCurrenciesList.find(
              (x) => x.CurrencyId == items.FeeCurrencyType
            )?.Name || items.FeeCurrencyType, // Ensure fallback in case of no match
          };
  
          this.props.onStateChangeAction({
            selectedOpportunity,
          });
  
          this.setState({
            declinedRequests: items.Declined || 0,
            acceptedRequests: items.Accepted || 0,
            pendingRequests: items.Total - (items.Accepted || 0) - (items.Declined || 0),
          });
  
          if (isEmpty(filterShortList)) {
            this.props.onStateChangeAction({
              selectedShortlists: [],
              selectedShortlist: null,
              shortlistResumes: [],
              selectedResume: {},
              selectedIpro: {},
              isFetchingShortlist: false,
            });
            return;
          }
  
          const selectedShortlist = head(filterShortList);
          this.props.onStateChangeAction({
            selectedShortlists: filterShortList,
            selectedShortlist,
            isFetchingShortlist: false,
          });
  
          getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
            .then((response) => {
              if (response.success) {
                const { items } = response;
                this.props.onStateChangeAction({
                  shortlistResumes: items,
                  selectedResume: {},
                  selectedIpro: {},
                  isFetchingShortlistResume: false,
                });
              }
              this.props.onStateChangeAction({ isLoading: false });
            })
            .catch(() => {
              this.props.onStateChangeAction({ isLoading: false });
            });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isFetchingShortlist: false,
          isFetchingShortlistResume: false,
          isLoading: false,
        });
      });
  };
  

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunities } = this.props;
    const filterOpportunires = filter(opportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower())
    );
    this.props.onStateChangeAction({ filterOpportunires, searchkey: value });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation();
    this.handleOpportunityClick({ selectedOpportunity });
    this.props.onStateChangeAction({
      selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };
  handleYesClick = () => {
    const { yesClickCount, currentOpportunityId } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 0,
    });

    this.props.onStateChangeAction({ isLoading: true });
    deleteOpportunityAndAssociationsApi({
      RequestId: currentOpportunityId,
    })
      .then((response) => {
        if (!response.success) {
          this.props.onStateChangeAction({
            selectedOpportunity: null,
          });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: response.message,
            status: "succes",
          };
          this.props.notificationAction(info);
        }
        this.deleteOpportunity({ RequestId: currentOpportunityId });
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
        });
      });
  };
  deleteOpportunity = ({ RequestId }) => {
    deleteSendOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.oppDeletedSuccessfully,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.props.onStateChangeAction({
            selectedOpportunity: null,
            opportunity: null,
          });
        }
        this.props.onStateChangeAction({
          selectedOpportunity: null,
          opportunity: null,
        });
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };
  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  handleNoClick = () => {
    const { dialogMessage, currentOpportunityId, yesClickCount } = this.props;
    const { labels } = this.props;
    if (
      dialogMessage ===
      labels.InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg
    ) {
      this.props.onStateChangeAction({ isLoading: true });
      this.deleteOpportunity({ RequestId: currentOpportunityId });
    }
    this.props.onStateChangeAction({ dialogMessage: "", yesClickCount: 0 });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
      });
      this.setState({
        isSearchFocus: false,
      });
      return;
    }
    this.setState({ isSearchFocus: false });
  };
  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      shortlistResumes: [],
      selectedResume: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        if (data.success) {
          const selectedResume = head(data.items);
          const selectedIpro =
            this.iprosList.find(
              (ipro) => ipro.ResumeId === selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            shortlistResumes: data.items,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleSelectedShortlistClick = ({ selectedShortlist }) => {
    getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          const selectedIpro =
            this.props.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist,
            shortlistResumes: items,
            selectedResume,
            selectedIpro,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
          });
        }
      })
      .catch((response) => {});
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    const selectedIpro =
      this.props.iprosList.find(
        (ipro) => ipro.ResumeId == selectedResume.ResumeId
      ) || {};
    this.props.onStateChangeAction({ selectedResume, selectedIpro });
  };

  handleResumeDelete = (item) => {
    let { shortlistResumes, selectedResume, selectedShortlist } = this.props;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          shortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.props.onStateChangeAction({
            shortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId ? {} : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  handleOkClick = () => {
    this.handleOpportunityUpdate({ IsSent: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  getOpportunityResumes = (searchkey) => {
    const { currentOpportunityId } = this.props;
    this.props.onStateChangeAction({ isLoading: true });
    getOpportunityResumesApi({ requestId: currentOpportunityId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          this.props.onStateChangeAction({
            shortlistResumes: items,
            // selectedResume: head(items),
            isFetchingShortlistResume: false,
          });
          const { shortlistResumes } = this.props;
          let filteredList;
          if (searchkey == 1) {
            filteredList = items.filter(
              (item) => item.IsConfirmed == true && !item.IsDeleted
            );
          } else if (searchkey == 2) {
            filteredList = items.filter(
              (item) => item.IsDeleted == true || item.IsDeclined == true
            );
          } else {
            filteredList = items.filter(
              (item) =>
                item.IsDeleted == false &&
                item.IsDeclined == false &&
                item.IsConfirmed == false
            );
          }
          const selectedResume = head(filteredList) || {};
          const selectedIpro =
            this.props.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            hideShortList: true,
            isLoading: false,
            showResumeList: true,
            shortlistResumes: filteredList,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch((response) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleAcceptedResumes = () => {
    this.getOpportunityResumes(1);
  };
  handleDeclinedResumes = () => {
    this.getOpportunityResumes(2);
  };
  handleNoActionResumes = () => {
    this.getOpportunityResumes(3);
  };
  handleCloseModal = () => {
    this.setState((st) => ({ ...st, showModal: false }));
  };
  handleModalClick = (data) => {
    this.setState((st) => ({ ...st, showModal: { show: true, data: data } }));
  };
  handleMobileModalClose = () => {
    this.setState((st) => ({
      ...st,
      mobileModal: false,
    }));
  };

  handleListOpenMobile = () => {
    this.setState((st) => ({ ...st, mobileModal: true }));
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const { declinedRequests, acceptedRequests, pendingRequests } = this.state;
    const {
      dialogMessage,
      isLoading,
      filterOpportunires,
      selectedOpportunity,
      selectedShortlists,
      shortlistResumes,
      currentOpportunityId,
      selectedAction,
    } = this.props;
    return (
      <PageWrapper className="searcher-draft-opportunity-page">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
              this.props.labels
                .InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-ok"
                  onClick={this.handleOkClick}
                >
                  Ok
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-yes"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {this.state?.showModal?.show == true && (
          <DetailModal
            setOpen={this.handleCloseModal}
            open={this.state.showModal}
            selected={[]}
            name="sent"
          />
        )}
        <MobileFilter
          isModalOpen={this?.state?.mobileModal}
          setIsModalOpen={this.handleMobileModalClose}
          resumeList={shortlistResumes}
          score={0}
          handleModalClick={this.handleModalClick}
          name="opportunity"
        />
        <Row className="new-search-resume-main">
          <Col xs={24} md={6} className="h-100 search-filter-right">
            <div className="h-100 flex flex-column">
              <div style={{ overflow: "auto" }} className="flex flex-column">
                <div
                  className="select-sent"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Select
                    style={{
                      width: "100%",
                      marginRight: "6px",
                      overflow: "auto",
                    }}
                    placeholder={
                      labels.SearcherOpportunitySentSearchPlaceholder
                    }
                    size="large"
                    optionLabelProp="label"
                    value={currentOpportunityId}
                    onChange={(e) => {
                      const filterOption = filterOpportunires?.find(
                        (row) => row?.value == e
                      );
                      this.handleOpportunityClick({
                        selectedOpportunity: filterOption,
                      });
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {filterOpportunires?.map((task) => {
                      return (
                        <Option
                          key={task.id}
                          value={task.value}
                          label={task.label}
                        >
                          <div className="align-items-center d-flex w-100 justify-content-between">
                            <span
                              style={{
                                paddingRight: "6px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {task.label}
                            </span>
                            <span>
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "16px",
                                  color: "#a8a6a6",
                                  lineHeight: "22px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleOpportunityDelete({
                                    selectedOpportunity: task,
                                    e,
                                  });
                                }}
                              />
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    overflow: "auto",
                    paddingRight: "6px",
                  }}
                >
                  {selectedOpportunity?.RequestName ? (
                    <NewOpportunityDetail
                      declinedRequests={declinedRequests}
                      acceptedRequests={acceptedRequests}
                      pendingRequests={pendingRequests}
                      selectedOpportunity={selectedOpportunity}
                      labels={labels}
                      onAcceptedResumes={this.handleAcceptedResumes}
                      onDeclinedResumes={this.handleDeclinedResumes}
                      onNoActionResumes={this.handleNoActionResumes}
                      handleListOpenMobile={this.handleListOpenMobile}
                      selectedShortlists={selectedShortlists}
                      selectedAction={selectedAction}
                      onSelectedActionChange={({ selectedAction }) =>
                        this.props.onStateChangeAction({
                          selectedAction,
                        })
                      }
                    />
                  ) : (
                    <EmptyInfo>{labels?.emptyOpportunityInfo}</EmptyInfo>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={18} xs={0} offset={0.5} className="h-100">
            <div className="column-secend">
              <ShortListResume
                resumeList={shortlistResumes}
                score={0}
                handleModalClick={this.handleModalClick}
                name="opportunity"
              />
            </div>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  searcherOpportunitySent,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    isHelpActive,
    ...searcherOpportunitySent,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(SearcherOpportunitySent);
