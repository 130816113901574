import React, { Component } from "react";
import { EmptyInfo, Button } from "../../../../../common";
import moment from "moment";
import "./NewOpportunityDetail.scss";
import { Col, Row } from "antd";

class NewOpportunityDetailIpro extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  render() {
    const {
      selectedOpportunity,
      emptyOpportunityInfo,
      selectInterest,
      onOpportunityRestore,
      IsSearcher,
      locationsLabel,
      startDateLabel,
      durationLabel,
      SearcherOpportunitySentDetailFee,
      companyLabel,
      descriptionLabel,

      SearchParameterHeading,
      Lbltitle,
      LblProfile,
      LblSkills,
      LblKeyword,
      LblIndustry,
      LblCertification,
      LblCountry,
      LblLanguage,
      LblInterest,
      LblRestore,
      BtnAccept,
      BtnDecline,
      BtnRestore,
      handleOpportunityDetailAcceptClick,
      handleOpportunityDetailDeclinedClick,
      handleOpportunityDetailCompanyClick,
    } = this.props;
    const retrieveSearch = selectedOpportunity.retrieveResumeSearch;
    return (
      <div className="new-opportunity-detail-ipro">
        {!selectedOpportunity.RequestId ? (
          <EmptyInfo>{emptyOpportunityInfo}</EmptyInfo>
        ) : (
          <div className="inner-user">
            <div className="title">
              <b> {selectedOpportunity.UserFirstname} </b>
              {"from"}
              {selectedOpportunity.Company && (
                <span
                  className="invCompValue"
                  test-data-id="showcompanyfromheading-opportunitynew-label"
                  onClick={handleOpportunityDetailCompanyClick}
                >
                  &nbsp;{selectedOpportunity.Company.CompanyName}&nbsp;
                </span>
              )}
              {Lbltitle}
            </div>

            <div
              className="title"
              style={{
                textAlign: "center",
                fontWeight: 600,
                marginBottom: "10px",
              }}
            >
              {selectedOpportunity?.RequestName}
            </div>

            {selectedOpportunity?.Countries?.length > 0 && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">{locationsLabel}:</p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity.Countries[0].CountryName}
                  </p>
                </Col>
              </Row>
            )}

            {selectedOpportunity.StartDate && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">{startDateLabel}:</p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {this.renderDate(selectedOpportunity.StartDate)}
                  </p>
                </Col>
              </Row>
            )}

            {selectedOpportunity.Duration && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">{durationLabel}:</p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity.Duration}&nbsp;
                    {selectedOpportunity.DurationType}{" "}
                  </p>
                </Col>
              </Row>
            )}

            {selectedOpportunity.HourlyFee && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">{SearcherOpportunitySentDetailFee}:</p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity.HourlyFee} &nbsp;
                    {selectedOpportunity.FeeCurrencyType}
                  </p>
                </Col>
              </Row>
            )}

            {selectedOpportunity.Company && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">{companyLabel}:</p>
                </Col>
                <Col flex="auto" onClick={handleOpportunityDetailCompanyClick}>
                  <p className="title">
                    {selectedOpportunity.Company.CompanyName}
                  </p>
                </Col>
              </Row>
            )}

            {selectedOpportunity.Description && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">{descriptionLabel}:</p>
                </Col>
                <Col flex="auto">
                  <p className="title">{selectedOpportunity.Description}</p>
                </Col>
              </Row>
            )}
            {retrieveSearch && (
              <div className="invSelScore">
                {SearchParameterHeading && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="auto">
                      <p className="title" style={{ fontWeight: 600 }}>
                        {SearchParameterHeading}:
                      </p>
                    </Col>
                  </Row>
                )}

                {retrieveSearch?.Profiles?.length > 0 && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="150px">
                      <p className="title">{LblProfile}:</p>
                    </Col>
                    <Col flex="auto">
                      {retrieveSearch?.Profiles?.map((searchItem, index) => (
                        <p className="title" key={index}>
                          {searchItem?.SearchedValue}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}

                {retrieveSearch?.Skills?.length > 0 && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="150px">
                      <p className="title">{LblSkills}:</p>
                    </Col>
                    <Col flex="auto">
                      {retrieveSearch.Skills.map((searchItem, index) => (
                        <p className="title" key={index}>
                          {searchItem.SearchedValue}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}

                {retrieveSearch.Keywords && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="150px">
                      <p className="title">{LblKeyword}:</p>
                    </Col>
                    <Col flex="auto">
                      {retrieveSearch.Keywords.map((searchItem, index) => (
                        <p className="title" key={index}>
                          {searchItem.KeywordValue}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}

                {retrieveSearch.Industeries && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="150px">
                      <p className="title">{LblIndustry}:</p>
                    </Col>
                    <Col flex="auto">
                      {retrieveSearch.Industeries.map((searchItem, index) => (
                        <p className="title" key={index}>
                          {searchItem.IndustryValue}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}

                {retrieveSearch.Certifications && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="150px">
                      <p className="title">{LblCertification}:</p>
                    </Col>
                    <Col flex="auto">
                      {retrieveSearch.Certifications.map(
                        (searchItem, index) => (
                          <p className="title" key={index}>
                            {searchItem.CertificationValue}
                          </p>
                        )
                      )}
                    </Col>
                  </Row>
                )}

                {retrieveSearch.Countries && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="150px">
                      <p className="title">{LblCountry}:</p>
                    </Col>
                    <Col flex="auto">
                      {retrieveSearch.Countries.map((searchItem, index) => (
                        <p className="title" key={index}>
                          {searchItem.CountryName}{" "}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}

                {retrieveSearch.Languages && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="150px">
                      <p className="title">{LblLanguage}:</p>
                    </Col>
                    <Col flex="auto">
                      {retrieveSearch.Languages.map((searchItem, index) => (
                        <p className="title" key={index}>
                          {searchItem.LanguageValue}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}
              </div>
            )}
            {selectedOpportunity.IsDeclined && !selectedOpportunity.IsDeleted && (
              <Row gutter={8} style={{ marginTop: "10px" }}>
                <Col xs={24}>
                  <p
                    className="title"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {LblRestore}
                  </p>
                </Col>
                <Col xs={24}>
                  <div className="btn-footer">
                    <button
                      className="btn-delete clr-purple "
                      onClick={() => onOpportunityRestore()}
                    >
                      {BtnRestore}
                    </button>
                  </div>
                </Col>
              </Row>
            )}

            <div className="">
              {!selectedOpportunity.IsConfirmed &&
                !selectedOpportunity.IsDeleted &&
                !selectedOpportunity.IsDeclined && (
                  <div className="">
                    <Row
                      gutter={[8, 12]}
                      style={{ marginTop: "10px", flexFlow: "unset" }}
                    >
                      <Col flex="auto">
                        <p className="title" style={{ fontWeight: 600 }}>
                          {LblInterest}:
                        </p>
                      </Col>
                    </Row>

                    <div className="btn-footer">
                      <button
                        className="btn-delete clr-purple "
                        onClick={() => handleOpportunityDetailAcceptClick()}
                      >
                        {BtnAccept}
                      </button>
                      <button
                        className="btn-delete clr-red ml-2"
                        onClick={() => handleOpportunityDetailDeclinedClick()}
                      >
                        {BtnDecline}
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NewOpportunityDetailIpro;
