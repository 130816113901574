import React, { Component } from "react";
import { Button } from "../../../../common";
import moment from "moment";
import "./newOpportunityDetail.scss";
import { Col, Divider, Row, Select } from "antd";
import MenuDots from "../../../../assets/newResume/menuDots";

class NewOpportunityDetail extends Component {
  renderDate = (date) => {
    return date ? moment(date).format("MM/DD/YY") : "N/A";
  };

  renderDuration = (duration, durationType) => {
    return duration ? `${duration} ${durationType}` : "N/A";
  };

  renderHourlyFee = (fee, currencyType) => {
    return fee ? `${fee} ${currencyType}` : "N/A";
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOpportunity !== this.props.selectedOpportunity) {
      this.props.onSelectedActionChange({ selectedAction: "" });
    }
  }

  render() {
    const {
      selectedOpportunity,
      onAcceptedResumes,
      onDeclinedResumes,
      onNoActionResumes,
      labels,
      selectedAction,
      onSelectedActionChange,
      declinedRequests,
      acceptedRequests,
      pendingRequests,
      selectedShortlists,
      handleListOpenMobile,
    } = this.props;

    return (
      <div className="new-opportunity-detail">
        <div>
          <div
            className="title"
            style={{
              textAlign: "center",
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {selectedOpportunity?.RequestName || "N/A"}
          </div>
          <div className="feedbackWrap">
            <div className="msgItemDiv">
              <Divider />
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailStartDate || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {this.renderDate(selectedOpportunity?.StartDate)}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailDuration || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {this.renderDuration(
                      selectedOpportunity?.Duration,
                      selectedOpportunity?.DurationType
                    )}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailFee || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {this.renderHourlyFee(
                      selectedOpportunity?.HourlyFee,
                      selectedOpportunity?.FeeCurrencyType
                    )}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailLocations || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity?.Countries || "N/A"}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailLanguages || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity?.Languages || "N/A"}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailCompany || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity?.CompanyName || "N/A"}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailSavedSearch || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity?.SearchName || "N/A"}
                  </p>
                </Col>
              </Row>
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="150px">
                  <p className="title">
                    {labels?.SearcherOpportunitySentDetailDescription || "N/A"}
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="title">
                    {selectedOpportunity?.Description || "N/A"}
                  </p>
                </Col>
              </Row>

              <Row
                className=""
                style={{
                  alignItems: "center",
                  paddingBottom: "12px",
                  marginTop: "12px",
                }}
              >
                <Col xs={22} md={24}>
                  <div>
                    <Select
                      size="large"
                      className={`bg-color-for-opportunity  ${
                        selectedOpportunity?.invalidShortList ? "inValid" : ""
                      }`}
                      bordered={false}
                      style={{ width: "100%" }}
                      value={selectedShortlists && selectedShortlists}
                      placeholder="Search and add your shortlist"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={selectedShortlists || []}
                    />
                  </div>
                </Col>
                <Col md={0} xs={2} onClick={() => handleListOpenMobile()}>
                  <MenuDots style={{ float: "right" }} />
                </Col>
              </Row>
            </div>
            <div className="action-buttons">
              <Button
                testId="sent-opportunity-detail-pending-label"
                className={`opp-pending ${
                  selectedAction === "pending" ? "active" : ""
                }`}
                onClick={() => {
                  onNoActionResumes();
                  onSelectedActionChange({ selectedAction: "pending" });
                }}
              >
                {labels?.SearcherOpportunitySentDetailPending}&nbsp;
                {pendingRequests || 0}
              </Button>
              <Button
                testId="sent-opportunity-detail-accepted-label"
                className={`opp-accepted ${
                  selectedAction === "accepted" ? "active" : ""
                }`}
                onClick={() => {
                  onAcceptedResumes();
                  onSelectedActionChange({ selectedAction: "accepted" });
                }}
              >
                {labels?.SearcherOpportunitySentDetailAccepted}&nbsp;
                {acceptedRequests || 0}
              </Button>
              <Button
                testId="sent-opportunity-detail-declined-label"
                onClick={() => {
                  onDeclinedResumes();
                  onSelectedActionChange({ selectedAction: "declined" });
                }}
                className={`opp-declined ${
                  selectedAction === "declined" ? "active" : ""
                }`}
              >
                {labels?.SearcherOpportunitySentDetailDeclined} &nbsp;
                {declinedRequests || 0}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewOpportunityDetail;
