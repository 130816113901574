import React, { Component } from "react";
import "./newUserDetail.scss";
import ImgSrc from "../../../../assets/images/company.svg";
import { Col, Row } from "antd";

function getBtnClass(url) {
  if (url.indexOf("facebook") > 0) return "facebook";
  else if (url.indexOf("twitter") > 0) return "twitter";
  else if (url.indexOf("google") > 0) return "googleplus";
  else if (url.indexOf("instagram") > 0) return "instagram";
  else if (url.indexOf("linkedin") > 0) return "linkedin";
  else if (url.indexOf("pinterest") > 0) return "pinterest";
  else if (url.indexOf("driblle") > 0) return "driblle";
  else if (url.indexOf("behance") > 0) return "behance";
  else return "";
}
class NewUserDetail extends Component {
  render() {
    const { selectedUser, labels } = this.props;
    return (
      <div className="new-user-detail-component">
        <div className="inner-user">
          <div className="image-user">
            <img
              src={(selectedUser && selectedUser.Logo) || ImgSrc}
              className={
                selectedUser && selectedUser.Logo
                  ? "roundImg"
                  : "emptyRoundImag"
              }
            />
          </div>
          <p className="user-title">{selectedUser && selectedUser.Title}</p>
          <Row
            gutter={[8, 12]}
            style={{ marginTop: "10px", flexFlow: "unset" }}
          >
            <Col flex="100px">
              <p className="user-detail">
                {labels.SearcherSentCollaborationUserNameLabel} :
              </p>
            </Col>
            <Col flex="auto">
              <p className="user-detail">{selectedUser.UserName}</p>
            </Col>
          </Row>
          {selectedUser.UserProfile && selectedUser.UserProfile && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail">
                  {" "}
                  {labels.SearcherSentCollaborationPositionLabel} :
                </p>
              </Col>
              <Col flex="auto">
                <p className="user-detail"> {selectedUser.UserProfile}</p>
              </Col>
            </Row>
          )}
          {selectedUser.UserEmail && selectedUser.UserEmail.UserEmailValue && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail">
                  {labels.SearcherSentCollaborationEmailLabel} :
                </p>
              </Col>

              <Col flex="auto">
                <p className="user-detail">
                  {selectedUser.UserEmail.UserEmailValue}
                </p>
              </Col>
            </Row>
          )}

          {selectedUser && selectedUser.UserPhonenumbers && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail">
                  {labels.SearcherSentCollaborationPhoneLabel} :
                </p>
              </Col>

              <Col flex="auto">
                <p className="user-detail">
                  {selectedUser.UserPhonenumbers.UserPhonenumberValue}
                </p>
              </Col>
            </Row>
          )}
          {selectedUser && selectedUser.WebUrl && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail">
                  {labels.SearcherSentCollaborationWebUrlLabel}:
                </p>
              </Col>

              <Col flex="auto">
                <p className="user-detail">
                  <a target="_blank" href={selectedUser.WebUrl}>
                    {selectedUser.WebUrl}
                  </a>
                </p>
              </Col>
            </Row>
          )}
          {selectedUser && selectedUser.Detail && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail">
                  {labels.SearcherSentCollaborationDetailLabel}:
                </p>
              </Col>

              <Col flex="auto">
                <p className="user-detail">{selectedUser.Detail}</p>
              </Col>
            </Row>
          )}
          {selectedUser.UserDetailSocialLink && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col span={24}>
                {selectedUser.UserDetailSocialLink.map((item, index) => (
                  <button
                    key={index}
                    className={`socialBtn ${getBtnClass(item.URL)}Btn ${
                      item.Id ? "activeBtn" : ""
                    }`}
                    onClick={() => {
                      window.open(item.URL, "_blank");
                    }}
                  />
                ))}
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default NewUserDetail;
